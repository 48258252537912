/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { CalibrationPriceDto } from '../models/calibration-price-dto';
import { CalibrationPriceDtodlmBaseResponse } from '../models/calibration-price-dtodlm-base-response';
import { CalibrationPriceDtoListDlmBaseResponse } from '../models/calibration-price-dto-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class CalibrationPriceService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCalibrationPriceCalibrationPricesGet
   */
  static readonly ApiCalibrationPriceCalibrationPricesGetPath = '/api/CalibrationPrice/calibration-prices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceCalibrationPricesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceCalibrationPricesGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceCalibrationPricesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceCalibrationPricesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceCalibrationPricesGet$Plain(params?: {
    archived?: boolean;
  }): Observable<CalibrationPriceDtoListDlmBaseResponse> {

    return this.apiCalibrationPriceCalibrationPricesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>) => r.body as CalibrationPriceDtoListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceCalibrationPricesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceCalibrationPricesGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceCalibrationPricesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceCalibrationPricesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceCalibrationPricesGet$Json(params?: {
    archived?: boolean;
  }): Observable<CalibrationPriceDtoListDlmBaseResponse> {

    return this.apiCalibrationPriceCalibrationPricesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtoListDlmBaseResponse>) => r.body as CalibrationPriceDtoListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationPriceCalibrationPricePost
   */
  static readonly ApiCalibrationPriceCalibrationPricePostPath = '/api/CalibrationPrice/calibration-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceCalibrationPricePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceCalibrationPricePost$Plain$Response(params?: {
    body?: CalibrationPriceDto
  }): Observable<StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceCalibrationPricePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceCalibrationPricePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceCalibrationPricePost$Plain(params?: {
    body?: CalibrationPriceDto
  }): Observable<CalibrationPriceDtodlmBaseResponse> {

    return this.apiCalibrationPriceCalibrationPricePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>) => r.body as CalibrationPriceDtodlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceCalibrationPricePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceCalibrationPricePost$Json$Response(params?: {
    body?: CalibrationPriceDto
  }): Observable<StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceCalibrationPricePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceCalibrationPricePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceCalibrationPricePost$Json(params?: {
    body?: CalibrationPriceDto
  }): Observable<CalibrationPriceDtodlmBaseResponse> {

    return this.apiCalibrationPriceCalibrationPricePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>) => r.body as CalibrationPriceDtodlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationPriceEditCalibrationPricePost
   */
  static readonly ApiCalibrationPriceEditCalibrationPricePostPath = '/api/CalibrationPrice/edit-calibration-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceEditCalibrationPricePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceEditCalibrationPricePost$Plain$Response(params?: {
    body?: CalibrationPriceDto
  }): Observable<StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceEditCalibrationPricePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceEditCalibrationPricePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceEditCalibrationPricePost$Plain(params?: {
    body?: CalibrationPriceDto
  }): Observable<CalibrationPriceDtodlmBaseResponse> {

    return this.apiCalibrationPriceEditCalibrationPricePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>) => r.body as CalibrationPriceDtodlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceEditCalibrationPricePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceEditCalibrationPricePost$Json$Response(params?: {
    body?: CalibrationPriceDto
  }): Observable<StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceEditCalibrationPricePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceEditCalibrationPricePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationPriceEditCalibrationPricePost$Json(params?: {
    body?: CalibrationPriceDto
  }): Observable<CalibrationPriceDtodlmBaseResponse> {

    return this.apiCalibrationPriceEditCalibrationPricePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationPriceDtodlmBaseResponse>) => r.body as CalibrationPriceDtodlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationPriceDeleteCalibrationPricePost
   */
  static readonly ApiCalibrationPriceDeleteCalibrationPricePostPath = '/api/CalibrationPrice/delete-calibration-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceDeleteCalibrationPricePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceDeleteCalibrationPricePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceDeleteCalibrationPricePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceDeleteCalibrationPricePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceDeleteCalibrationPricePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationPriceDeleteCalibrationPricePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceDeleteCalibrationPricePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceDeleteCalibrationPricePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceDeleteCalibrationPricePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceDeleteCalibrationPricePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceDeleteCalibrationPricePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationPriceDeleteCalibrationPricePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationPriceRestoreCalibrationPricePost
   */
  static readonly ApiCalibrationPriceRestoreCalibrationPricePostPath = '/api/CalibrationPrice/restore-calibration-price';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceRestoreCalibrationPricePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceRestoreCalibrationPricePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceRestoreCalibrationPricePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceRestoreCalibrationPricePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceRestoreCalibrationPricePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationPriceRestoreCalibrationPricePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationPriceRestoreCalibrationPricePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceRestoreCalibrationPricePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationPriceService.ApiCalibrationPriceRestoreCalibrationPricePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationPriceRestoreCalibrationPricePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationPriceRestoreCalibrationPricePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCalibrationPriceRestoreCalibrationPricePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
