import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TimeUtil } from 'app/core/utils/time-utils';

@Component({
  selector: 'app-edit-time-estimate',
  templateUrl: './edit-time-estimate.component.html',
  styleUrls: ['./edit-time-estimate.component.scss']
})
export class EditTimeEstimateComponent {
  title = 'Estimated Time';

  form: UntypedFormGroup;

  estimate: string;

  constructor(
    public dialogRef: MatDialogRef<EditTimeEstimateComponent>,
    private _formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void
  {
      this.estimate = this.data.estimate;
      this.buildForm();
  }

  buildForm() {
    var split = this.estimate.toString().split(' ');

    if(split.length == 1) {
      split[0].includes('h') ? split.push('0m') : split.unshift('0h');
    }

    var hours = parseInt(split[0]?.replace("h", "")) ?? 0;
    var minutes = parseInt(split[1]?.replace("m", "")) ?? 0;

    this.form = this._formBuilder.group({
      hours   : [ hours ],
      minutes : [ minutes ],
    });

    this.cdr.detectChanges();
  }

  forceInteger(event: any) {
    const value = event.target.value;
    event.target.value = Math.floor(value);
  }
  
  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close(null);
  }
}
