/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { BudgetService } from './services/budget.service';
import { CalibrationService } from './services/calibration.service';
import { CalibrationPriceService } from './services/calibration-price.service';
import { CompanyService } from './services/company.service';
import { ContactService } from './services/contact.service';
import { ContactImageService } from './services/contact-image.service';
import { CostManagerService } from './services/cost-manager.service';
import { CustomerService } from './services/customer.service';
import { DashboardService } from './services/dashboard.service';
import { DealService } from './services/deal.service';
import { ElectricalEquipmentService } from './services/electrical-equipment.service';
import { EmailService } from './services/email.service';
import { EmployeeService } from './services/employee.service';
import { ExaminationFaultService } from './services/examination-fault.service';
import { FileManagerService } from './services/file-manager.service';
import { FormService } from './services/form.service';
import { Form10AService } from './services/form-10-a.service';
import { GithubService } from './services/github.service';
import { HolidayService } from './services/holiday.service';
import { InvoiceService } from './services/invoice.service';
import { LabourCostSettingsService } from './services/labour-cost-settings.service';
import { LoadCellPhotoService } from './services/load-cell-photo.service';
import { LoadCellTypeService } from './services/load-cell-type.service';
import { LocationService } from './services/location.service';
import { MachineService } from './services/machine.service';
import { MarkupService } from './services/markup.service';
import { MeasurementService } from './services/measurement.service';
import { OutputDisplayTypeService } from './services/output-display-type.service';
import { PackagingService } from './services/packaging.service';
import { PartService } from './services/part.service';
import { PressureMachineService } from './services/pressure-machine.service';
import { PressureTestService } from './services/pressure-test.service';
import { ProductService } from './services/product.service';
import { ProjectService } from './services/project.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { RectificationWorkService } from './services/rectification-work.service';
import { RepairsAndCalibrationsService } from './services/repairs-and-calibrations.service';
import { ReportService } from './services/report.service';
import { ResourceService } from './services/resource.service';
import { SalesOrderService } from './services/sales-order.service';
import { ScheduleService } from './services/schedule.service';
import { ScrumService } from './services/scrum.service';
import { SerialNumberService } from './services/serial-number.service';
import { SettingsService } from './services/settings.service';
import { SlaSettingsService } from './services/sla-settings.service';
import { StaffRateService } from './services/staff-rate.service';
import { SupplierService } from './services/supplier.service';
import { ToDoService } from './services/to-do.service';
import { UserImageService } from './services/user-image.service';
import { WorkloadService } from './services/workload.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    BudgetService,
    CalibrationService,
    CalibrationPriceService,
    CompanyService,
    ContactService,
    ContactImageService,
    CostManagerService,
    CustomerService,
    DashboardService,
    DealService,
    ElectricalEquipmentService,
    EmailService,
    EmployeeService,
    ExaminationFaultService,
    FileManagerService,
    FormService,
    Form10AService,
    GithubService,
    HolidayService,
    InvoiceService,
    LabourCostSettingsService,
    LoadCellPhotoService,
    LoadCellTypeService,
    LocationService,
    MachineService,
    MarkupService,
    MeasurementService,
    OutputDisplayTypeService,
    PackagingService,
    PartService,
    PressureMachineService,
    PressureTestService,
    ProductService,
    ProjectService,
    PurchaseOrderService,
    RectificationWorkService,
    RepairsAndCalibrationsService,
    ReportService,
    ResourceService,
    SalesOrderService,
    ScheduleService,
    ScrumService,
    SerialNumberService,
    SettingsService,
    SlaSettingsService,
    StaffRateService,
    SupplierService,
    ToDoService,
    UserImageService,
    WorkloadService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
