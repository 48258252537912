import { TextFieldModule } from '@angular/cdk/text-field';
import { DatePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ContactDto, EnumViewModel, ProductFileDto, ProjectListDto, PurchaseOrderDto, ScrumCardCommentDto, ScrumCardDto, ScrumCardHistoryDto, ScrumLabelDto, SwimlaneDetailDto, SwimlaneListDto, TaskFileDto, TaskFileDtoBaseResponse, TaskPriority, UserDto } from 'app/api/models';
import { AccountService, ContactService, ProductService, ProjectService, PurchaseOrderService, ScrumService, SettingsService } from 'app/api/services';
import { ColourUtil } from 'app/core/utils/color-util';
import { TimeUtil } from 'app/core/utils/time-utils';
import { DateTime } from 'luxon';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import Compressor from 'compressorjs';
import { Subject } from 'rxjs';
import { ColorPickerModule } from 'ngx-color-picker';
import 'material-design-inspired-color-picker';
import { EditTimeEstimateComponent } from './edit-time-estimate/edit-time-estimate.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UploadFilePopupComponent } from 'app/views/admin/installation/documentations/upload-file-popup/upload-file-popup.component';
import { JwtAuthService } from 'app/api/services/cookie-jwt-auth/jwt-auth.service';
import { sortOnProperty } from 'app/app.component';
import { GenericCommentDto } from 'app/core/components/comments/comments.component';
import { SharedComponentsModule } from 'app/core/components/shared-components.module';
import { MatSliderModule } from '@angular/material/slider';
import { isNumber } from 'lodash';

@Component({
    selector       : 'scrumboard-card-details',
    templateUrl    : './details.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone     : true,
    styleUrls      : ['./details.component.scss'],
    imports        : [
        MatButtonModule, 
        MatIconModule, 
        FormsModule, 
        ReactiveFormsModule, 
        MatFormFieldModule, 
        MatInputModule, 
        MatOptionModule,
        TextFieldModule, 
        NgClass, 
        NgIf, 
        MatDatepickerModule, 
        NgFor, 
        MatCheckboxModule, 
        DatePipe, 
        QuillEditorComponent, 
        MatTooltipModule, 
        MatMenuModule,
        MatSelectModule,
        ColorPickerModule,
        MatSlideToggleModule,
        MatSliderModule,
        SharedComponentsModule,
    ],
})
export class ScrumboardCardDetailsComponent implements OnInit, OnDestroy
{
    @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
    @ViewChild('colourMenu') colourMenu: MatMenuTrigger;

    isAssetBoard: boolean = false;

    isAdmin: boolean = false;
    isBasic: boolean = true;
    currentUser: any;
    quotationFormFeatureEnabled: boolean = false;
    
    cardId: number;
    card: ScrumCardDto;
    genericComments: GenericCommentDto[];
    contact: ContactDto;
    
    cardForm: UntypedFormGroup;
    labels: ScrumLabelDto[] = [];
    filteredLabels: ScrumLabelDto[] = [];
    editingLabel: ScrumLabelDto;
    isBillable: boolean;
    requireForm: boolean;
    percentageComplete: number;
    files: TaskFileDto[] | ProductFileDto = [];
    originalUserIds: string;
    swimlanes: SwimlaneListDto[];

    selectLane: boolean = false;
    projects: ProjectListDto[] = [];
    selectedProjectId: number;
    laneOptions: SwimlaneDetailDto[] = [];

    public users: UserDto[] = [];
    public priorities = Object.values(TaskPriority).filter(x => isNumber(x)).map(x => <EnumViewModel> {name: this.getPriorityName(+x), value: <TaskPriority>x});

    public picker: HTMLElement;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private readonly _positionStep: number = 65536;

    quillEditor: any;
    quillModules: any = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline'],
                [{align: []}, {list: 'ordered'}, {list: 'bullet'}],                
                ['image'],
                ['clean'],
            ],
            handlers: {
              'image': this.imageHandler.bind(this)
            }
          }
    };
    activeSalesOrder: PurchaseOrderDto;

    constructor(
        public matDialogRef: MatDialogRef<ScrumboardCardDetailsComponent>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private _formBuilder: UntypedFormBuilder,
        private scrumService: ScrumService,
        private productService: ProductService,
        public accountService: AccountService,
        private projectService: ProjectService,
        private settingsService: SettingsService,
        private purchaseOrderService: PurchaseOrderService,
        private contactService: ContactService,
        private _fuseConfirmationService: FuseConfirmationService,
        private _matDialog: MatDialog,
        private jwtAuth: JwtAuthService,
        
        @Inject(MAT_DIALOG_DATA) public data: {cardId: number, selectLane: boolean, isAssetBoard: boolean}
    )
    {
        this.cardId = data.cardId;
        this.isAssetBoard = data?.isAssetBoard ?? false;
        this.selectLane = data?.selectLane ?? false;
        console.log("assetboard?", this.isAssetBoard);
    }

    imageHandler() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = () => {
          const file = input.files[0];
    
          if (file) {
            new Compressor(file, {
              quality: 0.6,
              maxWidth: 800,
              maxHeight: 800,
              success: (compressedFile) => {
                const reader = new FileReader();
                reader.readAsDataURL(compressedFile);
                reader.onload = () => {
                  const range = this.quillEditor.getSelection();
                  this.quillEditor.insertEmbed(range.index, 'image', reader.result, Quill.sources.USER);
                };
              },
              error: (err) => {
                console.error('Compression error:', err);
              },
            });
          }
        };
    }
    
    onEditorCreated(quill) {
        this.quillEditor = quill;
    }

    getPriorityName(priority: number) {
        return TaskPriority[priority].toString();
    }

    ngOnInit(): void
    {
        //change cdk-overlay-pane defaults
        var doc: any = document.getElementsByClassName("cdk-overlay-pane")[0];
        doc.style.maxWidth = "95vw";
        doc.style.maxHeight = "92vh";


        this.isAdmin = this.jwtAuth.isAdmin();
        this.isBasic = this.jwtAuth.isBasic();
        this.currentUser = this.jwtAuth.getUser();
        
        // Prepare the card form
        this.cardForm = this._formBuilder.group({
            id              : [ null ],
            projectId       : [ null, this.selectLane ? Validators.required : null],
            swimlaneId      : [ null, Validators.required],
            title           : ['', Validators.required],
            description     : [''],
            labels          : [[]],
            startDate       : [null],
            dueDate         : [null],
            hoursEstimate   : [null],
            userIds         : [[]],
            priority        : [TaskPriority.None],
            location        : [''],
        });
        this.isBillable = true;
        this.requireForm = false;
        this.percentageComplete = 0;

        //var cardId = Number(this.router.url.split('/').splice(-1, 1)); //for some reason isn't in activated Route data

        // get the projects
        this.projectService.apiProjectAllListProjectsGet$Json().subscribe(res => {
            if(!res?.data) return;

            this.projects = res.data;

            if(!this.isAssetBoard)
                this.loadCard();
            else
                this.loadProduct();
        });

        this.loadFiles();
        this.loadUsers();
        this.loadAllowedFeatures();

        // setup colour picker
        // this.picker = document.getElementById('picker') // get the color picker element
        // this.picker.addEventListener('change', this.editLabelColour())
    }

    loadCard() {
        // Get the board and card
        this.scrumService.apiScrumCardGet$Json$Response({cardId: this.cardId})
            .subscribe(res => {
                if(!res.body.data) return;
                this.card = res.body.data;

                this.card.history = sortOnProperty(this.card.history, 'timeStamp', false);
                
                console.log("card", this.card);
                this.genericComments = this.getGenericComments(this.card.comments);

                this.scrumService.apiScrumAllLabelsGet$Json$Response().subscribe(res => {
                    if(!res.body.data) return;
                    console.log("labels", res.body.data);
    
                    this.labels = res.body.data;
                    this.sortCardLabels();
    
                    this.filteredLabels = this.labels;
                    this.cdr.detectChanges();
                });
            
            this.getBoardByProjectId();

            this.originalUserIds = this.card?.userIds ?? "";
            var userIds = this.card?.userIds?.length ? this.card?.userIds?.split(',').map(x => Number.parseInt(x)) : [];
            console.log("user ids from card", userIds);

            // Fill the form
            this.cardForm.patchValue({
                id              : this.card.id ?? null,
                projectId       : this.card.projectId,
                swimlaneId      : this.card.swimlaneId,
                title           : this.card.title,
                description     : this.card.description,
                labels          : this.card.labels,
                startDate       : this.card.startDate,
                dueDate         : this.card.dueDate,
                hoursEstimate   : this.card.hoursEstimate,
                userIds         : userIds,
                priority        : this.card.priority,
                location        : this.card.location,
            });
            this.isBillable = this.card.isBillable;
            this.requireForm = this.card.requireForm;
            this.percentageComplete = this.card.percentageComplete;

            if(this.card.projectId) {
                this.selectedProjectId = this.card.projectId;
                this.updateProject({value: this.card.projectId});
            }

            if(this.card.swimlaneId)
                this.updateLane({value: this.card.swimlaneId});
        });
    }

    loadProduct() {
        this.productService.apiProductGetProductGet$Json$Response({productId: this.cardId}).subscribe(res => {
            if(!res?.body?.data) return;
            this.card = res.body.data;

            this.card.id = res.body.data.productId;
            this.card.title = res.body.data.name;
            this.card.labels = res.body.data.categoryId ? [ { title: res.body.data.categoryName, id: res.body.data.categoryId } ] : [];
            
            console.log("card", this.card);
            this.genericComments = this.getGenericComments(this.card.comments);

            this.productService.apiProductAllCategoriesGet$Json$Response().subscribe(res => {
                if(!res?.body?.data) return;
                console.log("labels", res.body.data);

                this.labels = res.body.data;

                for(var label of this.labels)
                    label.id = label['productCategoryId'];

                this.sortCardLabels();

                this.filteredLabels = this.labels;
                this.cdr.detectChanges();

                for(var label of this.card.labels)
                    label.colour = this.labels.find(x => x.id == label.id)?.colour;
            
                this.getBoardByProjectId();

                this.originalUserIds = this.card?.userIds ?? "";
                var userIds = this.card?.userIds?.length ? this.card?.userIds?.split(',').map(x => Number.parseInt(x)) : [];
                console.log("user ids from card", userIds);

                // Fill the form
                this.cardForm.patchValue({
                    id              : this.card.id ?? null,
                    projectId       : this.card.projectId,
                    swimlaneId      : this.card.swimlaneId,
                    title           : this.card.title,
                    description     : this.card.description,
                    labels          : this.card.labels,
                    startDate       : this.card.startDate,
                    dueDate         : this.card.dueDate,
                    hoursEstimate   : this.card.hoursEstimate,
                    userIds         : userIds,
                    priority        : this.card.priority,
                    location        : this.card.location,
                });
                this.isBillable = this.card.isBillable;
                this.requireForm = this.card.requireForm;
                this.percentageComplete = this.card.percentageComplete;

                if(this.card.swimlaneId)
                    this.updateLane({value: this.card.swimlaneId});

                this.loadPurchaseOrders();
            });
        });
    }

    sortCardLabels() {
        if(!this.labels || !this.labels.length) return;

        this.labels = sortOnProperty(this.labels, 'title');

        if(this.card.labels?.length) {
            //put card labels first
            var cardLabelIds = this.card.labels.map(x => x.id);

            var cardLabels = this.labels.filter(x => this.hasLabel(x));
            var notCardLabels = this.labels.filter(x => !this.hasLabel(x));

            console.log("card labels", cardLabels);
            this.labels = cardLabels;
            this.labels.push(...notCardLabels);
        }
    }

    loadAllowedFeatures() {
        this.settingsService.apiSettingsAllowedFeaturesGet$Json$Response().subscribe(res => {
            if(!res?.body?.data) return;

            // this.quotationFormFeatureEnabled = res.body.data.includes(Feature.Form_Quotation);
            // console.log("forms allowed", this.quotationFormFeatureEnabled);
        })
    }

    loadFiles() {
        if(!this.cardId) return;

        if(!this.isAssetBoard)
            this.scrumService.apiScrumGetFilesGet$Json({ taskId: this.cardId }).subscribe(res => {
                if(!res?.data) return;

                this.files = res.data;

                this.cdr.detectChanges();
            });
        else
            this.productService.apiProductGetFilesGet$Json({ productId: this.cardId }).subscribe(res => {
                if(!res?.data) return;

                this.files = res.data;

                this.cdr.detectChanges();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadUsers() {
        this.accountService?.apiAccountSearchAllGet$Json({ searchTerm: null, archived: false }).subscribe((res) => { 
            if(!res?.succeeded) return;
    
            this.users = res?.data;
    
            this.cdr.detectChanges();
        });
      }

    getUserName(id: number) {
        if(!id) return "Not Set";

        var user = this.users.find(u => u.id == id);
        return user?.firstName + ' ' + user?.lastName;
    }

    getUserNameInitialSurname(id: number) {
        if(!id) return "Not Set";

        var user = this.users.find(u => u.id == id);
        return user?.firstName + ' ' + user?.lastName?.charAt(0);
    }

    getUserFirstName(id: number) {
        if(!id) return "Not Set";

        var user = this.users.find(u => u.id == id);
        return user?.firstName;
    }

    changeEstimatePopup() {
        var estimateString = TimeUtil.getHoursText(this.cardForm.controls['hoursEstimate']?.value);

        var dialog = this._matDialog.open(EditTimeEstimateComponent, {
            data: {
                estimate: estimateString
            }
        });
      
        dialog.afterClosed().subscribe((dialogRes) => {
            if (!dialogRes) return;

            this.updateTotalHours(dialogRes?.hours ?? 0, dialogRes?.minutes ?? 0);
        });
    }

    updateTotalHours(hours: number, minutes: number) {
        var estimate = TimeUtil.getHoursFromHoursText(hours + "h " + minutes + "m");

        this.cardForm.controls['hoursEstimate']?.setValue(estimate);
        this.card.hoursEstimate = parseFloat(estimate);
        
        this.cdr.detectChanges();
    }

    getTotalHours() {
        var estimate = this.cardForm.controls['hoursEstimate']?.value;

        return TimeUtil.getHoursText(estimate);
    }

    public updateUser(event: any) {
        console.log(event);
        // if(!this.card) this.card = {};

        // this.card.userIds = event?.value;
        // this.cardForm.controls['userIds']?.setValue(event?.value);

        // this.cdr.detectChanges();
    }

    getUserTriggerList() {
        var userIds = this.cardForm?.controls['userIds']?.value ?? [];
        return userIds.map(x => this.getUserFirstName(x)).join(', ');
    }

    updateLane(event: any) {
        if(!this.card) this.card = {};

        this.card.swimlaneId = event?.value;
    }

    updateProject(event: any) {
        if(!this.card) this.card = {};
        
        var projectChanged = this.selectedProjectId != parseInt(event?.value);

        if (typeof event?.value === 'string') {
            this.selectedProjectId = parseInt(event?.value);
        } else {
            this.selectedProjectId = event.value;
        }

        if(projectChanged) {
            this.cardForm.controls['swimlaneId']?.setValue(null);
            this.card.swimlaneId = null;
        }

        if(this.selectLane)
            this.loadProjectBoard();
    }

    public onToggle(event: MatSlideToggleChange) {
        this.isBillable = event.checked;
    }

    public onToggleRequireForm(event: MatSlideToggleChange) {
        this.requireForm = event.checked;
    }

    loadProjectBoard() {
        if(this.isAssetBoard) return;

        this.scrumService.apiScrumBoardForProjectGet$Json({ projectId: this.selectedProjectId }).subscribe(res => {
            if(!res?.data) return;

            this.laneOptions = res.data.swimlanes;
            this.cdr.detectChanges();
        })
    }

    getBoardByProjectId() {
        if(!this.isAssetBoard && !this.card?.projectId) return;

        (!this.isAssetBoard ?
            this.scrumService.apiScrumBasicBoardForProjectGet$Json$Response({projectId: this.card.projectId}) :
            this.productService.apiProductAssetBoardGet$Json$Response()
        )
        .subscribe(
            res => {
                if(!res?.body?.data) return;

                this.swimlanes = res.body.data.swimlanes;

                this.cdr.detectChanges();
            }
        );
    }

    archiveCard() {
        const confirmation = this._fuseConfirmationService.open({
            title  : 'Delete Card',
            message: `Are you sure you want to remove this ${!this.isAssetBoard ? 'card' : 'product'}?`,
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });
    
        confirmation.afterClosed().subscribe((result) => {
          if (result === 'confirmed') {
            
            (!this.isAssetBoard ?
                this.scrumService.apiScrumToggleArchiveCardDelete$Json$Response({cardId: this.card.id, isArchive: true}) :
                this.productService.apiProductToggleArchiveProductPut$Json$Response({productId: this.card.id, isArchive: true})
            )
            .subscribe(res => {
                if(!res?.body?.data) return;

                this.close();
            });
          }
        });
    }

    async loadPurchaseOrders() {
      if(!this.isAssetBoard)
        return;
  
      var productName = this.card.title;
      console.log("product name", productName);
      
      this.purchaseOrderService.apiPurchaseOrderGetAllGet$Json$Response({productName: productName}).subscribe(res => {
        if(!res?.body?.data) return;
        
        var purchaseOrders = res.body.data;
        console.log("pos", purchaseOrders);

        if(purchaseOrders.length == 0) return;

        this.activeSalesOrder = purchaseOrders[0];
        //set start and end hire dates
        var items = this.activeSalesOrder.purchaseOrderItems.filter(x => x.description.toLowerCase().includes(productName.toLowerCase()));

        if(items.length == 0) return;

        var item = items[0];
        console.log("PO ITEM", item);
        
        this.cardForm.controls['startDate']?.setValue(item.hireFrom);
        this.cardForm.controls['dueDate']?.setValue(item.hireTo);
            
        this.cdr.detectChanges();

        this.loadContacts();
      });
    }

    loadContacts() {
      this.contactService.apiContactGetAllGet$Json$Response({includeLeads: false}).subscribe(res => {
        if (!res?.body?.data) return;
  
        var contacts = res.body.data;
        
        if(this.activeSalesOrder)
          this.contact = contacts.find(p => p.contactId == this.activeSalesOrder.contactId);
  
        this.cdr.detectChanges();
      });
    }

    getCollectionDate() {
        return new Date();
    }

    getSalesOrderNumber() {
        return this.activeSalesOrder ? `NEX-ORD-${String(this.activeSalesOrder.purchaseOrderId).padStart(4, '0').replace(',', '')}` : '';
    }

    getCalibrationDueDate() {
        return new Date();
    }

    getClientText() {
        if(!this.contact) return '';
        return this.activeSalesOrder ? this.activeSalesOrder.company + ' - ' + this.contact.name : '';
    }


    /**
     * Return whether the card has the given label
     *
     * @param label
     */
    hasLabel(label: ScrumLabelDto): boolean
    {
        return !!this.card?.labels?.find(cardLabel => cardLabel.id === label.id);
    }

    /**
     * Filter labels
     *
     * @param event
     */
    filterLabels(event): void
    {
        // Get the value
        const value = event.target.value.toLowerCase();

        // Filter the labels
        this.filteredLabels = this.labels.filter(label => label.title.toLowerCase().includes(value));
    }

    /**
     * Filter labels input key down event
     *
     * @param event
     */
    filterLabelsInputKeyDown(event): void
    {
        // Return if the pressed key is not 'Enter'
        if ( event.key !== 'Enter' )
        {
            return;
        }

        // If the label exists...
        if ( this.filteredLabels.length > 0 ) {
            this.addOrRemoveLabelFromCard();
            return;
        }
        
        // Otherwise, create a new label
        var newLabelName = event?.srcElement?.value ?? "";

        // Return if the label input is empty
        if (!newLabelName) return;

        // Create new label
        var newLabel = {
            title: newLabelName,
            colour: ColourUtil.getRandomMidColour()
        };

        if(!this.isAssetBoard)
            this.scrumService.apiScrumCreateLabelPost$Json$Response({body: newLabel}).subscribe(res => {
                if(!res?.body?.data) return;

                // reload the lists
                this.scrumService.apiScrumAllLabelsGet$Json$Response().subscribe(res => {
                    if(!res.body.data) return;

                    this.labels = res.body.data;
                    this.sortCardLabels();

                    this.filteredLabels = this.labels;

                    // re filter the list
                    this.filterLabels({
                        target: {
                            value: newLabel.title
                        }
                    });

                    this.addOrRemoveLabelFromCard();

                    this.cdr.detectChanges();
                    return;
                });
            });
        else
            this.productService.apiProductAddCategoryPost$Json$Response({body: newLabel}).subscribe(res => {
                if(!res?.body?.data) return;

                // reload the lists
                this.productService.apiProductAllCategoriesGet$Json$Response().subscribe(res => {
                    if(!res.body.data) return;

                    this.labels = res.body.data;
                    this.sortCardLabels();

                    this.filteredLabels = this.labels;

                    // re filter the list
                    this.filterLabels({
                        target: {
                            value: newLabel.title
                        }
                    });

                    this.addOrRemoveLabelFromCard();

                    this.cdr.detectChanges();
                    return;
                });
            });
        
    }

    addOrRemoveLabelFromCard() {
        // If there is a label...
        const label = this.filteredLabels[0];
        const isLabelApplied = this.card.labels.find(cardLabel => cardLabel.id === label.id);

        // If the found label is already applied to the card...
        if ( isLabelApplied )
        {
            // Remove the label from the card
            this.removeLabelFromCard(label);
        }
        else
        {
            // Otherwise add the label to the card
            this.addLabelToCard(label);
        }
    }

    editLabel(label: ScrumLabelDto) {
        if(!label) {
            this.cancelLabelEdit();
        } else {
            this.editingLabel = {
                id: label.id,
                title: label.title,
                colour: label.colour
            }
        }
    }

    editLabelName(event: any, label: ScrumLabelDto) {
        if(!event || !label) return;

        this.editingLabel.title = event.target.value;
    }

    getLabelTextColour(backgroundColour: string) {
        return ColourUtil.getContrastColor(backgroundColour);
    }

    getLabelBorderStyle(backgroundColour: string) {
        // if white, use a grey border
        if(backgroundColour == "#ffffff") return "1px solid #d1d1d1";

        return "1px solid " + backgroundColour;
    }

    editLabelColour(event: any, label: ScrumLabelDto) {
        if(!event || !label) return;

        this.editingLabel.colour = event.detail[0];
        this.colourMenu.closeMenu();
    }

    cancelLabelEdit() {
        this.editingLabel = null;
    }

    saveLabelEdit(event: any) {
        event.preventDefault();
        event.stopPropagation();

        if(!this.editingLabel) return;

        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateLabelPost$Json({body: this.editingLabel}) :
            this.productService.apiProductUpdateCategoryPut$Json({body: this.editingLabel})
        )
        .subscribe(res => {
            if(!res?.data) return;

            this.labels.find(l => l.id == this.editingLabel?.id).title = this.editingLabel?.title;
            this.labels.find(l => l.id == this.editingLabel?.id).colour = this.editingLabel?.colour; 

            this.filteredLabels.find(l => l.id == this.editingLabel?.id).title = this.editingLabel?.title;
            this.filteredLabels.find(l => l.id == this.editingLabel?.id).colour = this.editingLabel?.colour;
    
            this.editingLabel = null;
        });
    }

    /**
     * Toggle card label
     *
     * @param label
     * @param change
     */
    toggleProductTag(label: ScrumLabelDto, change: MatCheckboxChange): void
    {
        if ( change.checked )
        {
            this.addLabelToCard(label);
        }
        else
        {
            this.removeLabelFromCard(label);
        }
    }

    /**
     * Add label to the card
     *
     * @param label
     */
    addLabelToCard(label: ScrumLabelDto): void
    {
        if(!this.card) this.card = {};

        // Add the label
        if(!this.isAssetBoard)
            this.card.labels?.unshift(label);
        else
            this.card.labels = [label];

        // Update the card form data
        this.cardForm.get('labels').patchValue(this.card.labels);

        // Mark for check
        this.cdr.markForCheck();
    }

    /**
     * Remove label from the card
     *
     * @param label
     */
    removeLabelFromCard(label: ScrumLabelDto): void
    {
        if(!this.card) this.card = {};

        // Remove the label
        this.card.labels?.splice(this.card.labels?.findIndex(cardLabel => cardLabel.id === label.id), 1);

        // Update the card form data
        this.cardForm.get('labels').patchValue(this.card.labels);

        // Mark for check
        this.cdr.markForCheck();
    }

    /**
     * Check if the given date is overdue
     */
    isOverdue(date: string): boolean
    {
        return DateTime.fromISO(date).startOf('day') < DateTime.now().startOf('day');
    }

    // Files
    async openFileUpload(){
        // if(this.isAssetBoard) return;
        var dialog = this._matDialog.open(UploadFilePopupComponent);
  
        dialog.afterClosed().subscribe((dialogRes) => {
          if (!dialogRes) return;
  
          this.uploadImages(dialogRes);
        });    
    }

    deleteFile(file: TaskFileDto | ProductFileDto, event: any){
        event.stopPropagation();

        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
         title  : "Delete File",
         message: 'Are you sure you want to delete this file?',
         actions: {
             confirm: {
               label: "Delete",
             },
         },
       });
   
       // Subscribe to the confirmation dialog closed action
       confirmation.afterClosed().subscribe((result) => {
         // If the confirm button pressed...
         if ( result === 'confirmed' )
         {
            if(!this.isAssetBoard)
                this.scrumService.apiScrumDeleteFileFileIdDelete$Json({ fileId: file['taskFileId'] }).subscribe((res) => {
                    if(!res) return;
        
                    this.loadFiles();
                });
            else
                this.productService.apiProductDeleteFileFileIdDelete$Json$Response({ fileId: file['productFileId'] }).subscribe((res) => {
                    if(!res) return;
        
                    this.loadFiles();
                });
         }
       });
     }
  
    async uploadImages(files: FileList) {
        if (!this.cardId) return;
        
        var newFiles = Array.from(files);

        newFiles
            .forEach((file) => {
                var imageFormat = file.name!.split(".").pop()?.trim()?.toLowerCase();
                var blob: Blob = file;
                var name: string = file.name;

                if(!this.isAssetBoard)
                    this.scrumService.apiScrumUploadFileTaskIdPost$Json({ 
                        taskId: this.cardId, 
                        filename: name, 
                        body: { formFile: blob },
                    }).subscribe((res) => {
                        this.loadFiles();
                    });
                else
                    this.productService.apiProductUploadFileProductIdPost$Json({ 
                        productId: this.cardId, 
                        filename: name, 
                        body: { formFile: blob },
                    }).subscribe((res) => {
                        this.loadFiles();
                    });
            });
    }

    download(file: TaskFileDto | ProductFileDto){
        (!this.isAssetBoard ?
            this.scrumService.apiScrumDownloadFileFileIdGet({ fileId: file['taskFileId'] }) :
            this.productService.apiProductDownloadFileFileIdGet({ fileId: file['productFileId'] })
        )
        .subscribe((res) => {
            if(!res) return;

            // Convert the response to a Blob
            const blob = new Blob([res], { type: 'application/octet-stream' });

            // Create a link element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            // if the items name doesnt end with the file extension, add it
            if(!file?.name.endsWith(file?.type)){
                file.name += file?.type;
            }

            // Set the download attribute of the link to the desired file name
            link.download = file.name;

            // Simulate a click on the link
            link.click();

            // Release the reference to the blob
            window.URL.revokeObjectURL(url);
        });
    }

    async save(){
        if(!this.card) this.card = {};

        var cardUserIds = this.cardForm.controls['userIds']?.value;

        // update card object
        //this.card = this.cardForm.value;
        this.card.id = this.cardForm.controls['id']?.value;
        this.card.title = this.cardForm.controls['title']?.value;
        this.card.description = this.cardForm.controls['description']?.value;
        this.card.startDate = this.cardForm.controls['startDate']?.value ? TimeUtil.setTo1amUTC(new Date(this.cardForm.controls['startDate']?.value)).toISOString() : null;
        this.card.dueDate = this.cardForm.controls['dueDate']?.value ? TimeUtil.setTo2359UTC(new Date(this.cardForm.controls['dueDate']?.value)).toISOString() : null;
        this.card.hoursEstimate = this.cardForm.controls['hoursEstimate']?.value;
        this.card.userIds =  cardUserIds?.length ? cardUserIds?.join(',') : "";
        var newSwimlaneId = this.cardForm.controls['swimlaneId']?.value;
        this.card.labels = this.cardForm.controls['labels']?.value;
        this.card.isBillable = this.isBillable;
        this.card.requireForm = this.requireForm;
        this.card.percentageComplete = this.percentageComplete;
        this.card.priority = this.cardForm.controls['priority']?.value;
        this.card.location = this.cardForm.controls['location']?.value;

        this.card['name'] = this.cardForm.controls['title']?.value;
        this.card['client'] = this.cardForm.controls['location']?.value;
        var lbls: ScrumLabelDto[] = this.cardForm.controls['labels']?.value;
        this.card['categoryId'] = lbls.length ? lbls[0].id : null;

        if(newSwimlaneId && this.card.swimlaneId != +newSwimlaneId) {
            //recalc positions
            var res = await (
                !this.isAssetBoard ?
                this.scrumService.apiScrumPaginatedCardsForSwimlaneGet$Json$Response({SwimlaneId: newSwimlaneId, PageIndex: 0, PageSize: 1}) :
                this.productService.apiProductPaginatedProductsForSwimlaneGet$Json$Response({SwimlaneId: newSwimlaneId, PageIndex: 0, PageSize: 1})
            ).toPromise();
            var firstItem = res?.body?.data?.items ? res.body.data.items[0] : null;

            this.card.position = firstItem ? (firstItem.position / 2) : this._positionStep;
        }
        this.card.swimlaneId = newSwimlaneId;

        if(!this.card.id) {
            // add
            if(!this.isAssetBoard)
                this.scrumService.apiScrumAddCardPost$Json$Response({body: this.card}).subscribe(res => {
                });
            else
                this.productService.apiProductAddProductPost$Json$Response({body: this.card}).subscribe(res => {
                });

        } else {
            // Update the card on the server
            if(!this.isAssetBoard)
                this.scrumService.apiScrumUpdateCardDetailsPut$Json$Response({body: this.card, currentUserId: this.currentUser.id}).subscribe(res => {
                    if(!res?.body?.data) return;

                    if(this.originalUserIds == this.card.userIds) return; //don't add history entry if no change to assigned users

                    var description = this.card.userIds.length == 0 ? "Assigned users cleared." :
                        "Assigned users set to " + this.cardForm.controls['userIds']?.value.map(x => this.getUserName(x)).join(', ') + "."

                    // if(description == "Assigned users set to Not Set.") return;

                    var history: ScrumCardHistoryDto = {
                        scrumCardId: this.card.id,
                        timeStamp: new Date().toISOString(),
                        description: description,
                    };

                    this.scrumService.apiScrumAddCardHistoryPost$Json$Response({body: history}).subscribe(res => {});
                });
            else
                this.productService.apiProductUpdateProductPut$Json$Response({body: this.card}).subscribe(res => {
                });
        }

        // Mark for check
        this.cdr.markForCheck();

        this.close(newSwimlaneId);
    }

    close(newSwimlaneId: number = null){
        this.matDialogRef.close(newSwimlaneId);
    }


    getGenericComments = (comments: ScrumCardCommentDto[]): GenericCommentDto[] =>
      comments?.map(x => <GenericCommentDto>{
          id: x.scrumCardCommentId,
          parentId: x.scrumCardId,
          text: x.text,
          userId: x.userId,
          timeStamp: new Date(x.timeStamp)});
  
    addComment(comment: GenericCommentDto) {
        var scrumCardComment = <ScrumCardCommentDto>{
            scrumCardId: comment.parentId,
            userId: comment.userId,
            text: comment.text,
            timeStamp: comment.timeStamp.toISOString(),
        };
  
        if(!this.isAssetBoard)
            this.scrumService.apiScrumAddCommentPost$Json$Response({body: scrumCardComment}).subscribe(res => {
                if(!res?.body?.data) return;
        
                this.card.comments.push(res.body.data);
        
                this.genericComments = this.getGenericComments(this.card.comments);
        
                this.cdr.detectChanges();
            });
        else
        this.productService.apiProductAddCommentPost$Json$Response({body: scrumCardComment}).subscribe(res => {
            if(!res?.body?.data) return;
    
            this.card.comments.push(res.body.data);
    
            this.genericComments = this.getGenericComments(this.card.comments);
    
            this.cdr.detectChanges();
        });
    }
  
    updateComment(comment: GenericCommentDto) {
        var scrumCardComment = <ScrumCardCommentDto>{
            scrumCardCommentId: comment.id,
            scrumCardId: comment.parentId,
            userId: comment.userId,
            text: comment.text,
            timeStamp: comment.timeStamp.toISOString(),
        };
    
        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateCommentPut$Json$Response({body: scrumCardComment}) :
            this.productService.apiProductUpdateCommentPut$Json$Response({body: scrumCardComment})
        ).subscribe(res => {
            if(!res?.body?.data) return;
    
            var cardToChange = this.card.comments.find(x => x.scrumCardCommentId == scrumCardComment.scrumCardCommentId);
            cardToChange.text = scrumCardComment.text;
            cardToChange.timeStamp = scrumCardComment.timeStamp;
    
            this.genericComments = this.getGenericComments(this.card.comments);
    
            this.cdr.detectChanges();
        });
    }
  
    removeComment(id: number) {

        (!this.isAssetBoard ?
            this.scrumService.apiScrumDeleteCommentDelete$Json$Response({commentId: id}) :
            this.productService.apiProductDeleteCommentDelete$Json$Response({commentId: id})
        ).subscribe(res => {
            if(!res?.body?.data) return;
    
            var indexToRemove = this.card.comments.findIndex(x => x.scrumCardCommentId == id);
            this.card.comments.splice(indexToRemove, 1);
            
            this.genericComments = this.getGenericComments(this.card.comments);
            
            this.cdr.detectChanges();
        });
    }

    formatSlider(value: number): string {
        // if (value >= 100) {
        //   return Math.round(value / 100) + '%';
        // }
    
        return `${value}%`;
    }

    openLocation() {
        var location = this.cardForm.controls['location']?.value;

        if(!location) return;

        window.open("http://maps.apple.com/maps?q=" + location); //todo sanitize it??
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Read the given file for demonstration purposes
     *
     * @param file
     */
    private _readAsDataURL(file: File): Promise<any>
    {
        // Return a new promise
        return new Promise((resolve, reject) =>
        {
            // Create a new reader
            const reader = new FileReader();

            // Resolve the promise on success
            reader.onload = (): void =>
            {
                resolve(reader.result);
            };

            // Reject the promise on error
            reader.onerror = (e): void =>
            {
                reject(e);
            };

            // Read the file as the
            reader.readAsDataURL(file);
        });
    }
}
