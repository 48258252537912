/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { MachineResponse } from '../models/machine-response';
import { MachineResponseDlmBaseResponse } from '../models/machine-response-dlm-base-response';
import { MachineResponseListDlmBaseResponse } from '../models/machine-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class MachineService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMachineMachinesGet
   */
  static readonly ApiMachineMachinesGetPath = '/api/Machine/machines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineMachinesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineMachinesGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<MachineResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineMachinesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineMachinesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineMachinesGet$Plain(params?: {
    archived?: boolean;
  }): Observable<MachineResponseListDlmBaseResponse> {

    return this.apiMachineMachinesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseListDlmBaseResponse>) => r.body as MachineResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineMachinesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineMachinesGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<MachineResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineMachinesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineMachinesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineMachinesGet$Json(params?: {
    archived?: boolean;
  }): Observable<MachineResponseListDlmBaseResponse> {

    return this.apiMachineMachinesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseListDlmBaseResponse>) => r.body as MachineResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMachineNewMachinePost
   */
  static readonly ApiMachineNewMachinePostPath = '/api/Machine/new-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineNewMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineNewMachinePost$Plain$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineNewMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineNewMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineNewMachinePost$Plain(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineNewMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineNewMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineNewMachinePost$Json$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineNewMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineNewMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineNewMachinePost$Json(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineNewMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMachineEditMachinePost
   */
  static readonly ApiMachineEditMachinePostPath = '/api/Machine/edit-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineEditMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineEditMachinePost$Plain$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineEditMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineEditMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineEditMachinePost$Plain(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineEditMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineEditMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineEditMachinePost$Json$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineEditMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineEditMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineEditMachinePost$Json(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineEditMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMachineDeleteMachinePost
   */
  static readonly ApiMachineDeleteMachinePostPath = '/api/Machine/delete-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineDeleteMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineDeleteMachinePost$Plain$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineDeleteMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineDeleteMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineDeleteMachinePost$Plain(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineDeleteMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineDeleteMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineDeleteMachinePost$Json$Response(params?: {
    body?: MachineResponse
  }): Observable<StrictHttpResponse<MachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineDeleteMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineDeleteMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMachineDeleteMachinePost$Json(params?: {
    body?: MachineResponse
  }): Observable<MachineResponseDlmBaseResponse> {

    return this.apiMachineDeleteMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MachineResponseDlmBaseResponse>) => r.body as MachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMachineRestoreMachinePost
   */
  static readonly ApiMachineRestoreMachinePostPath = '/api/Machine/restore-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineRestoreMachinePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineRestoreMachinePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineRestoreMachinePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineRestoreMachinePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineRestoreMachinePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiMachineRestoreMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMachineRestoreMachinePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineRestoreMachinePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MachineService.ApiMachineRestoreMachinePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMachineRestoreMachinePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMachineRestoreMachinePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiMachineRestoreMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
