/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { CertificateType } from '../models/certificate-type';

@Injectable({
  providedIn: 'root',
})
export class EmailService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEmailSendPdfCertificateEmailPost
   */
  static readonly ApiEmailSendPdfCertificateEmailPostPath = '/api/Email/send-pdf-certificate-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailSendPdfCertificateEmailPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendPdfCertificateEmailPost$Plain$Response(params?: {
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;

    /**
     * 0 &#x3D; Calibration
     * 
     * 1 &#x3D; PressureTest
     * 
     * 2 &#x3D; Quotation
     */
    certificateType?: CertificateType;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmailService.ApiEmailSendPdfCertificateEmailPostPath, 'post');
    if (params) {
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
      rb.query('certificateType', params.certificateType, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmailSendPdfCertificateEmailPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendPdfCertificateEmailPost$Plain(params?: {
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;

    /**
     * 0 &#x3D; Calibration
     * 
     * 1 &#x3D; PressureTest
     * 
     * 2 &#x3D; Quotation
     */
    certificateType?: CertificateType;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmailSendPdfCertificateEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailSendPdfCertificateEmailPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendPdfCertificateEmailPost$Json$Response(params?: {
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;

    /**
     * 0 &#x3D; Calibration
     * 
     * 1 &#x3D; PressureTest
     * 
     * 2 &#x3D; Quotation
     */
    certificateType?: CertificateType;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmailService.ApiEmailSendPdfCertificateEmailPostPath, 'post');
    if (params) {
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
      rb.query('certificateType', params.certificateType, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmailSendPdfCertificateEmailPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendPdfCertificateEmailPost$Json(params?: {
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;

    /**
     * 0 &#x3D; Calibration
     * 
     * 1 &#x3D; PressureTest
     * 
     * 2 &#x3D; Quotation
     */
    certificateType?: CertificateType;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmailSendPdfCertificateEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmailSendRotEmailPost
   */
  static readonly ApiEmailSendRotEmailPostPath = '/api/Email/send-rot-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailSendRotEmailPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendRotEmailPost$Plain$Response(params?: {
    calibrationId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmailService.ApiEmailSendRotEmailPostPath, 'post');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmailSendRotEmailPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendRotEmailPost$Plain(params?: {
    calibrationId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmailSendRotEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmailSendRotEmailPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendRotEmailPost$Json$Response(params?: {
    calibrationId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmailService.ApiEmailSendRotEmailPostPath, 'post');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmailSendRotEmailPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiEmailSendRotEmailPost$Json(params?: {
    calibrationId?: number;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmailSendRotEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
