import { Injectable } from "@angular/core"
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppLoaderService } from "../app-loader/app-loader.service";

@Injectable({providedIn: 'root'})
export class SnackService
{
    constructor(private snack: MatSnackBar)
    {
    }

    public showMsg(
        snackMessage: string,
        loader: AppLoaderService = null
      ): void {
        loader?.close();
        this.snack.open(snackMessage, "OK", { duration: 4000, panelClass: "snackActionText" });
      }

}
