import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { AccountService, ProductService, ScrumService, UserImageService } from 'app/api/services';
import { ScrumBoardDetailDto, ScrumCardDto, SwimlaneDetailDto, UserDto } from 'app/api/models';
import { SnackService } from 'app/core/services/snackbar/snack.service';
import { AppLoaderService } from 'app/core/services/app-loader/app-loader.service';
import { ScrumboardCardDetailsComponent } from '../card/details/details.component';
import { MatDialog } from '@angular/material/dialog';
import { ColourUtil } from 'app/core/utils/color-util';
import { TimeUtil } from 'app/core/utils/time-utils';
import { CommonModule, CurrencyPipe, KeyValue, TitleCasePipe } from '@angular/common';
import { forEach } from 'lodash';
import { JwtAuthService } from 'app/api/services/cookie-jwt-auth/jwt-auth.service';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseAlertComponent } from '@fuse/components/alert';
import { SharedModule } from 'app/core/shared.module';
import { NgxColorsModule } from 'ngx-colors';
import { NgxPrintModule } from 'ngx-print';
import { ScrumboardBoardAddCardComponent } from './add-card/add-card.component';
import { ScrumboardBoardAddListComponent } from './add-list/add-list.component';

class SwimlanePagingInfo {
    swimlaneId: number;
    pagingInfo: {
        currentPage: number;
        pageSize: number;
        totalPages?: number;
        totalCount?: number;
        totalHours?: number;
    };
}

@Component({
    selector       : 'scrumboard-board',
    templateUrl    : './board.component.html',
    styleUrls      : ['./board.component.scss'],
    standalone     : true,
    imports        : [
        CommonModule,
        SharedModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        TitleCasePipe,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        FuseAlertComponent,
        MatRadioModule,
        CurrencyPipe,
        TextFieldModule,
        MatDatepickerModule,
        MatPaginatorModule,
        NgxPrintModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatMenuModule,
        CdkScrollable,
        CdkDropList,
        CdkDropListGroup,
        CdkDrag,
        CdkDragHandle,
        NgxColorsModule,
        RouterLink,
        RouterOutlet,
        ScrumboardBoardAddCardComponent,
        ScrumboardBoardAddListComponent,
    ]
})
export class ScrumboardBoardComponent implements OnInit, OnDestroy
{
    @ViewChild('colourMenu') colourMenu: MatMenuTrigger;
    
    @Input() projectId: number;
    @Input() isAssetBoard: boolean = false;

    @Input() searchTerm: string = null;
    @Input() selectedStatusId: number = 0;
    
    boardId: number;
    board: ScrumBoardDetailDto;
    listTitleForm: UntypedFormGroup;

    public users: UserDto[] = [];
    public avatarDictionary: KeyValue<number, string>[] = [];
    currentUser: any;

    public swimlanePagingInfo: SwimlanePagingInfo[] = [];

    private readonly _positionStep: number = 65536;
    private readonly _maxListCount: number = 200;
    private readonly _maxPosition: number = this._positionStep * 500;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    

    constructor(
        private activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private scrumService: ScrumService,
        private productService: ProductService,
        private _formBuilder: UntypedFormBuilder,
        private _fuseConfirmationService: FuseConfirmationService,
        private snackService: SnackService,
        private loader: AppLoaderService,
        private router: Router,
        public accountService: AccountService,
        private userImageService: UserImageService,
        private jwtAuth: JwtAuthService,
    )
    {
    }

    ngOnInit()
    {
        // Initialize the list title form
        this.listTitleForm = this._formBuilder.group({
            title: [''],
        });

        this.getBoardByProjectId();

        this.loadUsers();
        this.currentUser = this.jwtAuth.getUser();
    }

    loadUsers() {
        this.accountService?.apiAccountSearchAllGet$Json({ searchTerm: null, archived: null }).subscribe((res) => { 
            if(!res?.succeeded) return;

            this.users = res?.data;

            this.loadAvatars();
            this.cdr.detectChanges();
        });
    }

    loadAvatars() {
        var userIds = this.users.map(x => x.id);

        if(!userIds || userIds.length == 0) return;

        this.avatarDictionary = [];        
        userIds.forEach(id => {
            if (!this.avatarDictionary[id]) {
                this.avatarDictionary[id] = null;
            }
        });


        this.userImageService.apiUserImageAllUserPhotosPost$Json({ body: userIds }).subscribe(res => {
            if(!res?.data) return;

            forEach(res.data, (value, key) => {
                this.avatarDictionary[key] = `data:${value?.contentType};base64,${value?.data}`;
            });
        });
    }

    hasAvatar(userId: number) {
        if(!this.avatarDictionary) return false;

        return this.avatarDictionary[userId] != null;
    }

    getAvatar(userId: number) {
        if(!userId) return null;

        return this.avatarDictionary[userId];
    }

    onScroll(event: any, swimlane: SwimlaneDetailDto) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            console.log("Scrolled to end of swimlane", swimlane.title);

            var page = this.swimlanePagingInfo.find(x => x.swimlaneId == swimlane.id)?.pagingInfo;
            if(page.currentPage < page.totalPages && page.totalPages > 1) {
                page.currentPage++;
                console.log("scroll - get more cards - page", page.currentPage, swimlane.title);
                this.getCardsForSwimlane(swimlane.id);
            }
        }
    }
    scrollToTop(swimlaneId: number) {
        const element = document.querySelector(`#swimlane${swimlaneId}`);
        element.scrollIntoView();
    }

    getUser(id: number): UserDto {
        if(!id) return;

        return this.users.find(u => u.id == id);
    }

    getUserInitials(user: UserDto): string {
        if(!user) return;

        var initials = user.firstName?.charAt(0) + user.lastName?.charAt(0);
        return initials.toUpperCase();
    }

    getUserAvatar(id: number) {
        if(!id) return "Not Set";

        var user = this.users.find(u => u.id == id);
        return user?.firstName + ' ' + user?.lastName;
    }

    getUserName(userId: number) {
        var user = this.getUser(userId);
        if(!user) return '';

        return user?.firstName + ' ' + user?.lastName;
    }

    getUserColour(userId: number) {
        var user = this.getUser(userId);
        if(!user) return '';

        user['colour'] ??= ColourUtil.getRandomBgColour();
       
        return user['colour'];
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    initBoard() {
        this.activatedRoute.data.subscribe((data) => {
            this.board = data.board;

            if(this.board == null)
                this.snackService.showMsg("Error Loading Board");
        });
    }

    public getBoardByProjectId() {
        if(!this.projectId && !this.isAssetBoard) return;

        (!this.isAssetBoard ?
            this.scrumService.apiScrumBasicBoardForProjectGet$Json$Response({projectId: this.projectId}) :
            this.productService.apiProductAssetBoardGet$Json$Response()
        )
        .subscribe(
            res => {
                if(!res?.body?.data)
                    return this.snackService.showMsg("Error Loading Board");

                this.board = res.body.data;
                this.boardId = this.board.id;

                this.board.swimlanes.forEach(x => {
                    x.cards = [];
                    this.swimlanePagingInfo.push({swimlaneId: x.id, pagingInfo: {
                        currentPage: 0,
                        pageSize: 10,
                    }});

                    this.getCardsForSwimlane(x.id);
                });

                // this.cdr.detectChanges();
            },
            err => {
                this.snackService.showMsg("Error Loading Boards", this.loader);
            });
    }

    getCardsForSwimlane(swimlaneId: number) {
        var page = this.swimlanePagingInfo.find(x => x.swimlaneId == swimlaneId)?.pagingInfo;

        //add check for already at highest page number here as well?

        var request = {
            SwimlaneId: swimlaneId,
            PageIndex: page.currentPage,
            PageSize: page.pageSize,

            SearchTerm: this.searchTerm,
            StatusId: this.selectedStatusId,
        };

        (!this.isAssetBoard ?
            this.scrumService.apiScrumPaginatedCardsForSwimlaneGet$Json$Response(request) :
            this.productService.apiProductPaginatedProductsForSwimlaneGet$Json$Response(request)
        )
        .subscribe(res => {
            if(!res?.body?.data) {
                page.currentPage = Math.max(0, page.currentPage - 1);
                return this.snackService.showMsg("Error Loading Swimlane " + !this.isAssetBoard ? "Cards" : "Assets");
            }

            var swimlane = this.board.swimlanes.find(x => x.id == swimlaneId);

            if(page.currentPage == 0) swimlane.cards = [];

            swimlane.cards.push(...res.body.data.items);
            page.currentPage = res.body.data.currentPage;
            page.pageSize = res.body.data.pageSize;
            page.totalCount = res.body.data.totalCount;
            page.totalPages = res.body.data.totalPages;
            page.totalHours = res.body.data.totalValue;
            console.log(swimlane.cards);

            this.cdr.detectChanges();
        });
    }

    refreshSwimlane(swimlaneId: number) {
        this.swimlanePagingInfo.find(x => x.swimlaneId == swimlaneId).pagingInfo.currentPage = 0;
        this.getCardsForSwimlane(swimlaneId);
    }

    getTotalCountForSwimlane(swimlaneId: number) {
        var page = this.swimlanePagingInfo.find(x => x.swimlaneId == swimlaneId)?.pagingInfo;
        return page.totalCount;
    }

    getTotalHoursForSwimlane(swimlaneId: number) {
        if(this.isAssetBoard) return -1;

        var page = this.swimlanePagingInfo.find(x => x.swimlaneId == swimlaneId)?.pagingInfo;
        return this.getHoursText(page.totalHours);
    }

    getLabelTextColour(backgroundColour: string) {
        return ColourUtil.getContrastColor(backgroundColour);
    }

    getLabelBorderStyle(backgroundColour: string) {
        // if white, use a grey border
        if(backgroundColour == "#ffffff") return "1px solid #d1d1d1";

        return "1px solid " + backgroundColour;
    }
    
    getListColour = (swimlane: SwimlaneDetailDto) => this.board.swimlanes.find(x => x.id == swimlane.id).colour;

    getHoursText(hours: number) {
        return TimeUtil.getHoursText(hours);
    }

    getHoursTextMinimal(hours: number) {
        return TimeUtil.getHoursTextMinimal(hours);
    }

    getTotalHoursEstimated(list: SwimlaneDetailDto) {
        var total = list.cards.reduce((prev, cur) => prev + cur.hoursEstimate, 0);

        return this.getHoursText(total);
    }

    getTotalHoursLogged(){
        // TODO: this
    }

    delayedMenuClose(trigger: MatMenuTrigger){ // This is buggy and wip
        setTimeout(() => {
            trigger.closeMenu();
          }, 500); // 500ms delay
    }



    /**
     * Focus on the given element to start editing the list title
     *
     * @param listTitleInput
     */
    renameList(listTitleInput: HTMLElement): void
    {
        // Use timeout so it can wait for menu to close
        setTimeout(() =>
        {
            listTitleInput.focus();
        });
    }

    /**
     * Add new list
     *
     * @param title
     */
    addList(title: string): void
    {
        // Limit the max list count
        if ( this.board?.swimlanes?.length >= this._maxListCount )
        {
            return;
        }

        // Create a new swimlane model
        const swimlane: SwimlaneDetailDto = {
            boardId : this.board.id,
            position: this.board.swimlanes?.length ? this.board.swimlanes[this.board.swimlanes.length - 1].position + this._positionStep : this._positionStep,
            title   : title,
        };

        // Save the list
        this.scrumService
            .apiScrumAddSwimlanePost$Json$Response({body: swimlane})
            .subscribe(res => {
                this.getBoardByProjectId(); //todo do this better - setting paging and just fetch the new swimlane
            }
        );
    }

    /**
     * Update the swimlane title
     *
     * @param event
     * @param swimlane
     */
    updateListTitle(event: any, swimlane: SwimlaneDetailDto): void
    {
        // Get the target element
        const element: HTMLInputElement = event.target;

        // Get the new title
        const newTitle = element.value;

        // If the title is empty...
        if ( !newTitle || newTitle.trim() === '' )
        {
            // Reset to original title and return
            element.value = swimlane.title;
            return;
        }

        // Update the list title and element value
        swimlane.title = element.value = newTitle.trim();

        // Update the list
        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateSwimlanePut$Json$Response({body: swimlane}) :
            this.productService.apiProductUpdateSwimlanePut$Json$Response({body: swimlane})
        ).subscribe(
            res => {

            }
        );
    }
    
    changeSwimlaneColour(event: any, swimlane: SwimlaneDetailDto) {
        if(!event || !swimlane) return;

        swimlane.colour = event.detail[0];
        
        // Update the list
        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateSwimlanePut$Json$Response({body: swimlane}) :
            this.productService.apiProductUpdateSwimlanePut$Json$Response({body: swimlane})
        ).subscribe(
            res => {

            }
        );

        this.colourMenu.closeMenu();
    }

    /**
     * Delete the list
     *
     * @param id
     */
    deleteList(id): void
    {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title  : 'Delete list',
            message: 'Are you sure you want to delete this list and its cards? This action cannot be undone!',
            actions: {
                confirm: {
                    label: 'Delete',
                },
            },
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) =>
        {
            // If the confirm button pressed...
            if ( result === 'confirmed' )
            {
                // Delete the list
                (!this.isAssetBoard ?
                    this.scrumService.apiScrumDeleteSwimlaneDelete$Json$Response({swimlaneId: id}) :
                    this.productService.apiProductDeleteSwimlaneDelete$Json$Response({swimlaneId: id})
                ).subscribe(
                    res => {
                        this.getBoardByProjectId(); //todo do this locally
                    }
                );
            }
        });
    }

    /**
     * Add new card
     */
    addCard(swimlane: SwimlaneDetailDto, title: string): void
    {
        var newAtTop = true;
        var poses = swimlane.cards.map(x => x.position);
        var lowestPos = Math.min(...(poses.length ? poses : [this._positionStep]));
        var pos = newAtTop ? Math.ceil(lowestPos * 7/8) : swimlane.cards.length ? swimlane.cards[swimlane.cards.length - 1].position + this._positionStep : this._positionStep;

        // Create a new card model
        const card: ScrumCardDto = ({
            swimlaneId  : swimlane.id,
            position    : pos,
            title       : title,
            isBillable  : true,
        });

        card['name'] = title; // for products

        // Save the card
        if(!this.isAssetBoard)
            this.scrumService.apiScrumAddCardPost$Json$Response({body: card}).subscribe(
                res => {
                    this.refreshSwimlane(swimlane.id);
                }
            );
        else
            this.productService.apiProductAddProductPost$Json$Response({body: card}).subscribe(
                res => {
                    this.refreshSwimlane(swimlane.id);
                }
            );
        
    }

    openCardDetails(card: ScrumCardDto): void {
        console.log("card", card);
        // if(!this.isAssetBoard)
        this._matDialog.open(ScrumboardCardDetailsComponent, {autoFocus: false, data: { cardId: card.id, isAssetBoard: this.isAssetBoard }})
            .afterClosed()
            .subscribe((res) =>
            {
                this.refreshSwimlane(card.swimlaneId);
                if(res)
                    this.refreshSwimlane(res);
            });
    }

    completeTask(event: Event, card: ScrumCardDto) {
        if(this.isAssetBoard) return;

        event.stopPropagation();
        card.isCompleted = !card.isCompleted;
    
        this.scrumService.apiScrumCompleteTaskPut$Json$Response({cardId: card.id, userId: this.currentUser.id, completed: card.isCompleted}).subscribe(res => {
          if(!res?.body?.data) return;
          //todo error handling
          this.refreshSwimlane(card.swimlaneId);
        });
    }

    /**
     * List dropped
     *
     * @param event
     */
    listDropped(event: CdkDragDrop<SwimlaneDetailDto[]>): void
    {
        // Move the item
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

        // Calculate the positions
        const updated = this._calculatePositions(event);

        // Update the swimlanes
        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateSwimlanesPut$Json$Response({body: updated}) :
            this.productService.apiProductUpdateSwimlanesPut$Json$Response({body: updated})
        ).subscribe(
        );
    }

    /**
     * Card dropped
     *
     * @param event
     */
    cardDropped(event: CdkDragDrop<ScrumCardDto[]>): void
    {
        // Move or transfer the item
        if ( event.previousContainer === event.container )
        {
            // Move the item
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else
        {
            // Transfer the item
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

            // Update the card's list id
            event.container.data[event.currentIndex].swimlaneId = Number(event.container.id);
        }

        // Calculate the positions
        const updated = this._calculatePositions(event);

        // Update the cards
        (!this.isAssetBoard ?
            this.scrumService.apiScrumUpdateCardPositionsPut$Json$Response({body: updated}) :
            this.productService.apiProductUpdateProductPositionsPut$Json$Response({body: updated})
        ).subscribe(
            res => {
                if(event.previousContainer.id !== event.container.id)
                    this.refreshSwimlane(+event.container.id);
                this.refreshSwimlane(+event.previousContainer.id);
            }
        );
    }

    /**
     * Check if the given ISO_8601 date string is overdue
     *
     * @param date
     */
    isOverdue(date: string): boolean
    {
        return DateTime.fromISO(date).startOf('day') < DateTime.now().startOf('day');
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Calculate and set item positions
     * from given CdkDragDrop event
     *
     * @param event
     * @private
     */
    private _calculatePositions(event: CdkDragDrop<any[]>): any[]
    {
        // Get the items
        let items = event.container.data;
        const currentItem = items[event.currentIndex];
        const prevItem = items[event.currentIndex - 1] || null;
        const nextItem = items[event.currentIndex + 1] || null;

        // If the item moved to the top...
        if ( !prevItem )
        {
            // If the item moved to an empty container
            if ( !nextItem )
            {
                currentItem.position = this._positionStep;
            }
            else
            {
                currentItem.position = nextItem.position / 2;
            }
        }
        // If the item moved to the bottom...
        else if ( !nextItem )
        {
            currentItem.position = prevItem.position + this._positionStep;
        }
        // If the item moved in between other items...
        else
        {
            currentItem.position = (prevItem.position + nextItem.position) / 2;
        }

        // Check if all item positions need to be updated
        if ( !Number.isInteger(currentItem.position) || currentItem.position >= this._maxPosition )
        {
            // Re-calculate all orders
            items = items.map((value, index) =>
            {
                value.position = (index + 1) * this._positionStep;
                return value;
            });

            // Return items
            return items;
        }

        // Return currentItem
        return [currentItem];
    }
}
