/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LabourCostSettingsResponse } from '../models/labour-cost-settings-response';
import { LabourCostSettingsResponseDlmBaseResponse } from '../models/labour-cost-settings-response-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class LabourCostSettingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLabourCostSettingsLabourCostSettingsGet
   */
  static readonly ApiLabourCostSettingsLabourCostSettingsGetPath = '/api/LabourCostSettings/labour-cost-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLabourCostSettingsLabourCostSettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLabourCostSettingsLabourCostSettingsGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabourCostSettingsService.ApiLabourCostSettingsLabourCostSettingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLabourCostSettingsLabourCostSettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLabourCostSettingsLabourCostSettingsGet$Plain(params?: {
  }): Observable<LabourCostSettingsResponseDlmBaseResponse> {

    return this.apiLabourCostSettingsLabourCostSettingsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>) => r.body as LabourCostSettingsResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLabourCostSettingsLabourCostSettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLabourCostSettingsLabourCostSettingsGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabourCostSettingsService.ApiLabourCostSettingsLabourCostSettingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLabourCostSettingsLabourCostSettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLabourCostSettingsLabourCostSettingsGet$Json(params?: {
  }): Observable<LabourCostSettingsResponseDlmBaseResponse> {

    return this.apiLabourCostSettingsLabourCostSettingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>) => r.body as LabourCostSettingsResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLabourCostSettingsLabourCostSettingsPost
   */
  static readonly ApiLabourCostSettingsLabourCostSettingsPostPath = '/api/LabourCostSettings/labour-cost-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLabourCostSettingsLabourCostSettingsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLabourCostSettingsLabourCostSettingsPost$Plain$Response(params?: {
    body?: LabourCostSettingsResponse
  }): Observable<StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabourCostSettingsService.ApiLabourCostSettingsLabourCostSettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLabourCostSettingsLabourCostSettingsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLabourCostSettingsLabourCostSettingsPost$Plain(params?: {
    body?: LabourCostSettingsResponse
  }): Observable<LabourCostSettingsResponseDlmBaseResponse> {

    return this.apiLabourCostSettingsLabourCostSettingsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>) => r.body as LabourCostSettingsResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLabourCostSettingsLabourCostSettingsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLabourCostSettingsLabourCostSettingsPost$Json$Response(params?: {
    body?: LabourCostSettingsResponse
  }): Observable<StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LabourCostSettingsService.ApiLabourCostSettingsLabourCostSettingsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLabourCostSettingsLabourCostSettingsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLabourCostSettingsLabourCostSettingsPost$Json(params?: {
    body?: LabourCostSettingsResponse
  }): Observable<LabourCostSettingsResponseDlmBaseResponse> {

    return this.apiLabourCostSettingsLabourCostSettingsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LabourCostSettingsResponseDlmBaseResponse>) => r.body as LabourCostSettingsResponseDlmBaseResponse)
    );
  }

}
