/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { MeasurementResponse } from '../models/measurement-response';
import { MeasurementResponseDlmBaseResponse } from '../models/measurement-response-dlm-base-response';
import { MeasurementResponseListDlmBaseResponse } from '../models/measurement-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class MeasurementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMeasurementMeasurementsGet
   */
  static readonly ApiMeasurementMeasurementsGetPath = '/api/Measurement/measurements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementMeasurementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementMeasurementsGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<MeasurementResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementMeasurementsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementMeasurementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementMeasurementsGet$Plain(params?: {
    archived?: boolean;
  }): Observable<MeasurementResponseListDlmBaseResponse> {

    return this.apiMeasurementMeasurementsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseListDlmBaseResponse>) => r.body as MeasurementResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementMeasurementsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementMeasurementsGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<MeasurementResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementMeasurementsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementMeasurementsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementMeasurementsGet$Json(params?: {
    archived?: boolean;
  }): Observable<MeasurementResponseListDlmBaseResponse> {

    return this.apiMeasurementMeasurementsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseListDlmBaseResponse>) => r.body as MeasurementResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMeasurementMeasurementPost
   */
  static readonly ApiMeasurementMeasurementPostPath = '/api/Measurement/measurement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementMeasurementPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementMeasurementPost$Plain$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementMeasurementPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementMeasurementPost$Plain(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementMeasurementPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementMeasurementPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementMeasurementPost$Json$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementMeasurementPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementMeasurementPost$Json(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementMeasurementPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMeasurementEditMeasurementPost
   */
  static readonly ApiMeasurementEditMeasurementPostPath = '/api/Measurement/edit-measurement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementEditMeasurementPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementEditMeasurementPost$Plain$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementEditMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementEditMeasurementPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementEditMeasurementPost$Plain(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementEditMeasurementPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementEditMeasurementPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementEditMeasurementPost$Json$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementEditMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementEditMeasurementPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementEditMeasurementPost$Json(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementEditMeasurementPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMeasurementDeleteMeasurementPost
   */
  static readonly ApiMeasurementDeleteMeasurementPostPath = '/api/Measurement/delete-measurement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementDeleteMeasurementPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementDeleteMeasurementPost$Plain$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementDeleteMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementDeleteMeasurementPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementDeleteMeasurementPost$Plain(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementDeleteMeasurementPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementDeleteMeasurementPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementDeleteMeasurementPost$Json$Response(params?: {
    body?: MeasurementResponse
  }): Observable<StrictHttpResponse<MeasurementResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementDeleteMeasurementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MeasurementResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementDeleteMeasurementPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMeasurementDeleteMeasurementPost$Json(params?: {
    body?: MeasurementResponse
  }): Observable<MeasurementResponseDlmBaseResponse> {

    return this.apiMeasurementDeleteMeasurementPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MeasurementResponseDlmBaseResponse>) => r.body as MeasurementResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMeasurementRestorePost
   */
  static readonly ApiMeasurementRestorePostPath = '/api/Measurement/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiMeasurementRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMeasurementRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MeasurementService.ApiMeasurementRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMeasurementRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMeasurementRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiMeasurementRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
