/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { DlmAccessLevel } from '../models/dlm-access-level';
import { EmployeeResponse } from '../models/employee-response';
import { EmployeeResponseDlmBaseResponse } from '../models/employee-response-dlm-base-response';
import { EmployeeResponseListDlmBaseResponse } from '../models/employee-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiEmployeeEmployeeGet
   */
  static readonly ApiEmployeeEmployeeGetPath = '/api/Employee/employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeGet$Plain(params?: {
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEmployeeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeGet$Json(params?: {
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEmployeeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeEmployeesGet
   */
  static readonly ApiEmployeeEmployeesGetPath = '/api/Employee/employees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeesGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<EmployeeResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeesGet$Plain(params?: {
    archived?: boolean;
  }): Observable<EmployeeResponseListDlmBaseResponse> {

    return this.apiEmployeeEmployeesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseListDlmBaseResponse>) => r.body as EmployeeResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeesGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<EmployeeResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeesGet$Json(params?: {
    archived?: boolean;
  }): Observable<EmployeeResponseListDlmBaseResponse> {

    return this.apiEmployeeEmployeesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseListDlmBaseResponse>) => r.body as EmployeeResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeCreateEmployeePost
   */
  static readonly ApiEmployeeCreateEmployeePostPath = '/api/Employee/create-employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeCreateEmployeePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeCreateEmployeePost$Plain$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeCreateEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeCreateEmployeePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeCreateEmployeePost$Plain(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeCreateEmployeePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeCreateEmployeePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeCreateEmployeePost$Json$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeCreateEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeCreateEmployeePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeCreateEmployeePost$Json(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeCreateEmployeePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeEditEmployeePost
   */
  static readonly ApiEmployeeEditEmployeePostPath = '/api/Employee/edit-employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEditEmployeePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeEditEmployeePost$Plain$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEditEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEditEmployeePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeEditEmployeePost$Plain(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEditEmployeePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEditEmployeePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeEditEmployeePost$Json$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEditEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEditEmployeePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeEditEmployeePost$Json(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEditEmployeePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeDeleteEmployeePost
   */
  static readonly ApiEmployeeDeleteEmployeePostPath = '/api/Employee/delete-employee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeDeleteEmployeePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeDeleteEmployeePost$Plain$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeDeleteEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeDeleteEmployeePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeDeleteEmployeePost$Plain(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeDeleteEmployeePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeDeleteEmployeePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeDeleteEmployeePost$Json$Response(params?: {
    body?: EmployeeResponse
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeDeleteEmployeePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeDeleteEmployeePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiEmployeeDeleteEmployeePost$Json(params?: {
    body?: EmployeeResponse
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeDeleteEmployeePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeJobTitlesGet
   */
  static readonly ApiEmployeeJobTitlesGetPath = '/api/Employee/job-titles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeJobTitlesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeJobTitlesGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DlmAccessLevel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeJobTitlesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DlmAccessLevel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeJobTitlesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeJobTitlesGet$Plain(params?: {
  }): Observable<Array<DlmAccessLevel>> {

    return this.apiEmployeeJobTitlesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DlmAccessLevel>>) => r.body as Array<DlmAccessLevel>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeJobTitlesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeJobTitlesGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DlmAccessLevel>>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeJobTitlesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DlmAccessLevel>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeJobTitlesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeJobTitlesGet$Json(params?: {
  }): Observable<Array<DlmAccessLevel>> {

    return this.apiEmployeeJobTitlesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DlmAccessLevel>>) => r.body as Array<DlmAccessLevel>)
    );
  }

  /**
   * Path part for operation apiEmployeeRestorePost
   */
  static readonly ApiEmployeeRestorePostPath = '/api/Employee/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmployeeRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiEmployeeRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiEmployeeEmployeeBackupPost
   */
  static readonly ApiEmployeeEmployeeBackupPostPath = '/api/Employee/employee-backup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeeBackupPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeBackupPost$Plain$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeeBackupPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeeBackupPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeBackupPost$Plain(params?: {
    email?: string;
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEmployeeBackupPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiEmployeeEmployeeBackupPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeBackupPost$Json$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<EmployeeResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, EmployeeService.ApiEmployeeEmployeeBackupPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmployeeResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiEmployeeEmployeeBackupPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiEmployeeEmployeeBackupPost$Json(params?: {
    email?: string;
  }): Observable<EmployeeResponseDlmBaseResponse> {

    return this.apiEmployeeEmployeeBackupPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmployeeResponseDlmBaseResponse>) => r.body as EmployeeResponseDlmBaseResponse)
    );
  }

}
