/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { PackagingResponse } from '../models/packaging-response';
import { PackagingResponseDlmBaseResponse } from '../models/packaging-response-dlm-base-response';
import { PackagingResponseListDlmBaseResponse } from '../models/packaging-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class PackagingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPackagingPackagingsGet
   */
  static readonly ApiPackagingPackagingsGetPath = '/api/Packaging/packagings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingPackagingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingPackagingsGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PackagingResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingPackagingsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingPackagingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingPackagingsGet$Plain(params?: {
    archived?: boolean;
  }): Observable<PackagingResponseListDlmBaseResponse> {

    return this.apiPackagingPackagingsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseListDlmBaseResponse>) => r.body as PackagingResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingPackagingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingPackagingsGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PackagingResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingPackagingsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingPackagingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingPackagingsGet$Json(params?: {
    archived?: boolean;
  }): Observable<PackagingResponseListDlmBaseResponse> {

    return this.apiPackagingPackagingsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseListDlmBaseResponse>) => r.body as PackagingResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPackagingPackagingPost
   */
  static readonly ApiPackagingPackagingPostPath = '/api/Packaging/packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingPackagingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingPackagingPost$Plain$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingPackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingPackagingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingPackagingPost$Plain(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingPackagingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingPackagingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingPackagingPost$Json$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingPackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingPackagingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingPackagingPost$Json(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingPackagingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPackagingEditPackagingPost
   */
  static readonly ApiPackagingEditPackagingPostPath = '/api/Packaging/edit-packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingEditPackagingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingEditPackagingPost$Plain$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingEditPackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingEditPackagingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingEditPackagingPost$Plain(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingEditPackagingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingEditPackagingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingEditPackagingPost$Json$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingEditPackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingEditPackagingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingEditPackagingPost$Json(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingEditPackagingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPackagingDeletePackagingPost
   */
  static readonly ApiPackagingDeletePackagingPostPath = '/api/Packaging/delete-packaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingDeletePackagingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingDeletePackagingPost$Plain$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingDeletePackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingDeletePackagingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingDeletePackagingPost$Plain(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingDeletePackagingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingDeletePackagingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingDeletePackagingPost$Json$Response(params?: {
    body?: PackagingResponse
  }): Observable<StrictHttpResponse<PackagingResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingDeletePackagingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackagingResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingDeletePackagingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPackagingDeletePackagingPost$Json(params?: {
    body?: PackagingResponse
  }): Observable<PackagingResponseDlmBaseResponse> {

    return this.apiPackagingDeletePackagingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PackagingResponseDlmBaseResponse>) => r.body as PackagingResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPackagingRestorePost
   */
  static readonly ApiPackagingRestorePostPath = '/api/Packaging/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPackagingRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPackagingRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PackagingService.ApiPackagingRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPackagingRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPackagingRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPackagingRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
