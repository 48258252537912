<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<div
    [class.sticky]="!isLocked && !isScreenSmall"
    [style.display]="isLocked || isScreenSmall ? 'none' : 'flex'"
    style="width: 85px;">
</div>

<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden fix-top-margin"
    [style.position]="isLocked && !isScreenSmall ? 'sticky' : 'fixed'"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container *ngIf="navigationAppearance == 'default'" fuseVerticalNavigationContentHeader>
        <div style="height: 272.733px;">
            <div class="flex items-center w-full p-4 pl-6">
                <!-- Logo -->
                <div class="flex items-center justify-center">
                    <img
                        [class.w-16]="env.company == 'elevated'"
                        [class.w-12]="env.company != 'elevated'"
                        [src]="themeService.getCompanyLogo()">
                </div>
                <!-- Components -->
                <div class="flex items-center ml-auto">
                    <notifications (click)="switchLocked(true)"></notifications>
                    <user (click)="switchLocked(true)" [showAvatar]="false"></user>
                    <button
                        mat-icon-button
                        style="margin-top: -4px;"
                        (click)="toggleNavigation()">
                        <mat-icon [svgIcon]="lockIcon"></mat-icon>
                    </button>
                </div>
            </div>
            <!-- User -->
            <div class="flex flex-col items-center w-full p-4">
                <div class="relative w-24 h-24">
                    <img
                        class="w-full h-full rounded-full object-cover"
                        *ngIf="avatarImageUrl"
                        [src]="avatarImageUrl"
                        alt="User avatar">
                    <mat-icon
                        class="icon-size-24"
                        *ngIf="!avatarImageUrl"
                        [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
                </div>
                <div class="flex flex-col items-center justify-center w-full mt-6">
                    <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                        {{user?.firstName + ' ' + user?.lastName}}
                    </div>
                    <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium">
                        {{user?.email}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="navigationAppearance == 'dense'" fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center flex-col" style="gap: 8px; height: 272.733px; padding-top: 16px;">
            <img
                class="w-8 mb-4"
                [src]="themeService.getCompanyLogo()"
                alt="Logo image">
            <notifications class="opacity-50"></notifications>
            <user class="opacity-50" [showAvatar]="false"></user>
            <button
                mat-icon-button
                class="opacity-50"
                (click)="toggleNavigation()">
                <mat-icon [svgIcon]="lockIcon"></mat-icon>
            </button>
            <!-- <div class="w-full border-b mt-auto mb-auto"></div> -->
        </div>
    </ng-container>

    <!-- Navigation footer hook -->
    <!-- <ng-container fuseVerticalNavigationContentFooter>
        <div class="flex flex-0 items-center justify-center h-16 pr-6 pl-2 mt-2 mb-4 opacity-12">
            <img
                class="max-w-36"
                [src]="themeService.getCompanyLogo()">
        </div>
    </ng-container> -->

    <!-- <ng-container fuseVerticalNavigationContentFooter>
        <button
            style="position: absolute; top: 0; right: -20px; top: 20px"
            mat-icon-button
            (click)="toggleNavigation()">
            <mat-icon [svgIcon]="lockIcon"></mat-icon>
        </button>
    </ng-container> -->
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        [class.hidden]="!isScreenSmall || isBasic">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation()">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <!-- <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <shortcuts></shortcuts>
        </div> -->
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Nexus &copy; {{currentYear}}</span>
    </div>-->

</div>

