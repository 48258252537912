/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoadCellPhotoResponse } from '../models/load-cell-photo-response';
import { LoadCellPhotoResponseDlmBaseResponse } from '../models/load-cell-photo-response-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class LoadCellPhotoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLoadCellPhotoUploadImageForm10AIdPost
   */
  static readonly ApiLoadCellPhotoUploadImageForm10AIdPostPath = '/api/LoadCellPhoto/upload-image/{form10aId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoUploadImageForm10AIdPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLoadCellPhotoUploadImageForm10AIdPost$Plain$Response(params: {
    form10aId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoUploadImageForm10AIdPostPath, 'post');
    if (params) {
      rb.path('form10aId', params.form10aId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoUploadImageForm10AIdPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLoadCellPhotoUploadImageForm10AIdPost$Plain(params: {
    form10aId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<LoadCellPhotoResponseDlmBaseResponse> {

    return this.apiLoadCellPhotoUploadImageForm10AIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>) => r.body as LoadCellPhotoResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoUploadImageForm10AIdPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLoadCellPhotoUploadImageForm10AIdPost$Json$Response(params: {
    form10aId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoUploadImageForm10AIdPostPath, 'post');
    if (params) {
      rb.path('form10aId', params.form10aId, {});
      rb.query('filename', params.filename, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoUploadImageForm10AIdPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiLoadCellPhotoUploadImageForm10AIdPost$Json(params: {
    form10aId: number;
    filename?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<LoadCellPhotoResponseDlmBaseResponse> {

    return this.apiLoadCellPhotoUploadImageForm10AIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>) => r.body as LoadCellPhotoResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLoadCellPhotoForm10APhotosGet
   */
  static readonly ApiLoadCellPhotoForm10APhotosGetPath = '/api/LoadCellPhoto/form10a-photos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoForm10APhotosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoForm10APhotosGet$Plain$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<Array<LoadCellPhotoResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoForm10APhotosGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoadCellPhotoResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoForm10APhotosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoForm10APhotosGet$Plain(params?: {
    form10aId?: number;
  }): Observable<Array<LoadCellPhotoResponse>> {

    return this.apiLoadCellPhotoForm10APhotosGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LoadCellPhotoResponse>>) => r.body as Array<LoadCellPhotoResponse>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoForm10APhotosGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoForm10APhotosGet$Json$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<Array<LoadCellPhotoResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoForm10APhotosGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoadCellPhotoResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoForm10APhotosGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoForm10APhotosGet$Json(params?: {
    form10aId?: number;
  }): Observable<Array<LoadCellPhotoResponse>> {

    return this.apiLoadCellPhotoForm10APhotosGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LoadCellPhotoResponse>>) => r.body as Array<LoadCellPhotoResponse>)
    );
  }

  /**
   * Path part for operation apiLoadCellPhotoGetImageLoadCellPhotoIdGet
   */
  static readonly ApiLoadCellPhotoGetImageLoadCellPhotoIdGetPath = '/api/LoadCellPhoto/get-image/{loadCellPhotoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoGetImageLoadCellPhotoIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoGetImageLoadCellPhotoIdGet$Response(params: {
    loadCellPhotoId: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoGetImageLoadCellPhotoIdGetPath, 'get');
    if (params) {
      rb.path('loadCellPhotoId', params.loadCellPhotoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/octet-stream'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoGetImageLoadCellPhotoIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoGetImageLoadCellPhotoIdGet(params: {
    loadCellPhotoId: number;
  }): Observable<string> {

    return this.apiLoadCellPhotoGetImageLoadCellPhotoIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete
   */
  static readonly ApiLoadCellPhotoDeleteImageLoadCellPhotoIdDeletePath = '/api/LoadCellPhoto/delete-image/{loadCellPhotoId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Plain$Response(params: {
    loadCellPhotoId: number;
  }): Observable<StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoDeleteImageLoadCellPhotoIdDeletePath, 'delete');
    if (params) {
      rb.path('loadCellPhotoId', params.loadCellPhotoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Plain(params: {
    loadCellPhotoId: number;
  }): Observable<LoadCellPhotoResponseDlmBaseResponse> {

    return this.apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>) => r.body as LoadCellPhotoResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Json$Response(params: {
    loadCellPhotoId: number;
  }): Observable<StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LoadCellPhotoService.ApiLoadCellPhotoDeleteImageLoadCellPhotoIdDeletePath, 'delete');
    if (params) {
      rb.path('loadCellPhotoId', params.loadCellPhotoId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Json(params: {
    loadCellPhotoId: number;
  }): Observable<LoadCellPhotoResponseDlmBaseResponse> {

    return this.apiLoadCellPhotoDeleteImageLoadCellPhotoIdDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoadCellPhotoResponseDlmBaseResponse>) => r.body as LoadCellPhotoResponseDlmBaseResponse)
    );
  }

}
