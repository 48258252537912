import { CommonModule } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ProductCategoryDto } from "app/api/models";
import { ProductService } from "app/api/services";
import { LocalStoreService } from "app/core/services/local-store.service";
import { SharedModule } from "app/core/shared.module";
import { ScrumboardBoardComponent } from "app/views/admin/pages/projects/project/scrumboard/board/board.component";

@Component({
    selector       : 'app-asset-board',
    templateUrl    : './asset-board.component.html',
    standalone     : true,
    imports        : [
        CommonModule,
        SharedModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        ScrumboardBoardComponent,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
    ]
})
export class AssetBoardComponent implements OnInit
{
    public searchTerm: string = null;
    public selectedStatusId: number = 0;
    public queryStatusId?: number = null;
    public statuses: ProductCategoryDto[] = [];

    @ViewChild('assetBoard') assetBoard: ScrumboardBoardComponent;

    constructor(
        private productService: ProductService,
        private ls: LocalStoreService,
    ) {

    }

    ngOnInit(): void {
        this.selectedStatusId = this.ls.getItem('asset_board_filtered_statusId') || 0;
        this.queryStatusId = this.selectedStatusId || null;
        this.loadStatuses();
    }

    loadStatuses() {
        this.productService.apiProductAllCategoriesGet$Json$Response().subscribe(res => {
            if(!res?.body?.data) return;
    
            this.statuses = res.body.data;
        });
    }

    filterStatus(event: any) {
        this.selectedStatusId = event?.value;
        this.queryStatusId = this.selectedStatusId || null;
        this.ls.setItem('asset_board_filtered_statusId', this.selectedStatusId);
        this.assetBoard?.getBoardByProjectId();
    }
    
    search(event: any) {
        this.searchTerm = event?.target?.value;
        this.assetBoard?.getBoardByProjectId();
    }

    getStatusText(statusId: number) {
        return this.statuses.find(x => x.productCategoryId == statusId)?.title;
    }

    getStatusFilterText(statusId: number) {
        if(statusId == -1) return 'All';

        return this.getStatusText(statusId);
    }
}
