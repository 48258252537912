/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { PressureMachineResponse } from '../models/pressure-machine-response';
import { PressureMachineResponseDlmBaseResponse } from '../models/pressure-machine-response-dlm-base-response';
import { PressureMachineResponseListDlmBaseResponse } from '../models/pressure-machine-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class PressureMachineService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPressureMachinePressureMachinesGet
   */
  static readonly ApiPressureMachinePressureMachinesGetPath = '/api/PressureMachine/pressure-machines';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachinePressureMachinesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachinePressureMachinesGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachinePressureMachinesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachinePressureMachinesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachinePressureMachinesGet$Plain(params?: {
    archived?: boolean;
  }): Observable<PressureMachineResponseListDlmBaseResponse> {

    return this.apiPressureMachinePressureMachinesGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>) => r.body as PressureMachineResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachinePressureMachinesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachinePressureMachinesGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachinePressureMachinesGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachinePressureMachinesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachinePressureMachinesGet$Json(params?: {
    archived?: boolean;
  }): Observable<PressureMachineResponseListDlmBaseResponse> {

    return this.apiPressureMachinePressureMachinesGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseListDlmBaseResponse>) => r.body as PressureMachineResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPressureMachineNewPressureMachinePost
   */
  static readonly ApiPressureMachineNewPressureMachinePostPath = '/api/PressureMachine/new-pressure-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineNewPressureMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineNewPressureMachinePost$Plain$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineNewPressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineNewPressureMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineNewPressureMachinePost$Plain(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineNewPressureMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineNewPressureMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineNewPressureMachinePost$Json$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineNewPressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineNewPressureMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineNewPressureMachinePost$Json(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineNewPressureMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPressureMachineEditPressureMachinePost
   */
  static readonly ApiPressureMachineEditPressureMachinePostPath = '/api/PressureMachine/edit-pressure-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineEditPressureMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineEditPressureMachinePost$Plain$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineEditPressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineEditPressureMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineEditPressureMachinePost$Plain(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineEditPressureMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineEditPressureMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineEditPressureMachinePost$Json$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineEditPressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineEditPressureMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineEditPressureMachinePost$Json(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineEditPressureMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPressureMachineDeletePressureMachinePost
   */
  static readonly ApiPressureMachineDeletePressureMachinePostPath = '/api/PressureMachine/delete-pressure-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineDeletePressureMachinePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineDeletePressureMachinePost$Plain$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineDeletePressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineDeletePressureMachinePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineDeletePressureMachinePost$Plain(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineDeletePressureMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineDeletePressureMachinePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineDeletePressureMachinePost$Json$Response(params?: {
    body?: PressureMachineResponse
  }): Observable<StrictHttpResponse<PressureMachineResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineDeletePressureMachinePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PressureMachineResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineDeletePressureMachinePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPressureMachineDeletePressureMachinePost$Json(params?: {
    body?: PressureMachineResponse
  }): Observable<PressureMachineResponseDlmBaseResponse> {

    return this.apiPressureMachineDeletePressureMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PressureMachineResponseDlmBaseResponse>) => r.body as PressureMachineResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPressureMachineRestorePressureMachinePost
   */
  static readonly ApiPressureMachineRestorePressureMachinePostPath = '/api/PressureMachine/restore-pressure-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineRestorePressureMachinePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachineRestorePressureMachinePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineRestorePressureMachinePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineRestorePressureMachinePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachineRestorePressureMachinePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPressureMachineRestorePressureMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPressureMachineRestorePressureMachinePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachineRestorePressureMachinePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PressureMachineService.ApiPressureMachineRestorePressureMachinePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPressureMachineRestorePressureMachinePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPressureMachineRestorePressureMachinePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPressureMachineRestorePressureMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
