<div class="popup flex flex-col justify-content-between">
    <label
        for="fileInput"
        class="flex flex-col align-items-center text-center cursor-pointer mt-5 pb-4"
    >
        <mat-icon [svgIcon]="'heroicons_outline:arrow-up-tray'" class="upload-icon w-full"></mat-icon>
        <p class="bold">Drag & Drop</p>
        <p>your files here, or click to browse</p>
        <input
            id="fileInput"
            type="file"
            (change)="onFileSelected($event)"
            class="hidden-input"
            #fileInput
            multiple
        />
        <p *ngIf="selectedFiles?.length">{{getSelectedFilesText()}}</p>
    </label>
    <div class="flex flex-row justify-end w-full mt-auto">
        <button
            class="mr-2"
            mat-raised-button
            color="warn"
            (click)="onClose()"
        >
            Cancel
        </button>

        <button
            mat-raised-button
            color="accent"
            (click)="onUpload()"
            [disabled]="!selectedFiles?.length"
        >
            Upload
        </button>
    </div>
</div>
