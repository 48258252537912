/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalibrationResponse } from '../models/calibration-response';
import { CalibrationResponseDlmBaseResponse } from '../models/calibration-response-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class CalibrationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCalibrationCalibrationGet
   */
  static readonly ApiCalibrationCalibrationGetPath = '/api/Calibration/calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Plain$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Plain(params?: {
    calibrationId?: number;
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Json$Response(params?: {
    calibrationId?: number;
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationGetPath, 'get');
    if (params) {
      rb.query('calibrationId', params.calibrationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCalibrationCalibrationGet$Json(params?: {
    calibrationId?: number;
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationCalibrationPost
   */
  static readonly ApiCalibrationCalibrationPostPath = '/api/Calibration/calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Plain$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Plain(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationCalibrationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Json$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationCalibrationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationCalibrationPost$Json(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationCalibrationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationEditCalibrationPost
   */
  static readonly ApiCalibrationEditCalibrationPostPath = '/api/Calibration/edit-calibration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationEditCalibrationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Plain$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationEditCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationEditCalibrationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Plain(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationEditCalibrationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationEditCalibrationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Json$Response(params?: {
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationEditCalibrationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationEditCalibrationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationEditCalibrationPost$Json(params?: {
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationEditCalibrationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationDeleteEquipmentPost
   */
  static readonly ApiCalibrationDeleteEquipmentPostPath = '/api/Calibration/delete-equipment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteEquipmentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Plain$Response(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteEquipmentPostPath, 'post');
    if (params) {
      rb.query('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteEquipmentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Plain(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteEquipmentPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteEquipmentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Json$Response(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteEquipmentPostPath, 'post');
    if (params) {
      rb.query('equipmentId', params.equipmentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteEquipmentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteEquipmentPost$Json(params?: {
    equipmentId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteEquipmentPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCalibrationDeleteResultPost
   */
  static readonly ApiCalibrationDeleteResultPostPath = '/api/Calibration/delete-result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteResultPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Plain$Response(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteResultPostPath, 'post');
    if (params) {
      rb.query('resultId', params.resultId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteResultPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Plain(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteResultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCalibrationDeleteResultPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Json$Response(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<StrictHttpResponse<CalibrationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CalibrationService.ApiCalibrationDeleteResultPostPath, 'post');
    if (params) {
      rb.query('resultId', params.resultId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalibrationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCalibrationDeleteResultPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCalibrationDeleteResultPost$Json(params?: {
    resultId?: number;
    body?: CalibrationResponse
  }): Observable<CalibrationResponseDlmBaseResponse> {

    return this.apiCalibrationDeleteResultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CalibrationResponseDlmBaseResponse>) => r.body as CalibrationResponseDlmBaseResponse)
    );
  }

}
