/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { LocationResponse } from '../models/location-response';
import { LocationResponseDlmBaseResponse } from '../models/location-response-dlm-base-response';
import { LocationResponseListDlmBaseResponse } from '../models/location-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class LocationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiLocationLocationsGet
   */
  static readonly ApiLocationLocationsGetPath = '/api/Location/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationLocationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationLocationsGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<LocationResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationLocationsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationLocationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationLocationsGet$Plain(params?: {
    archived?: boolean;
  }): Observable<LocationResponseListDlmBaseResponse> {

    return this.apiLocationLocationsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseListDlmBaseResponse>) => r.body as LocationResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationLocationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationLocationsGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<LocationResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationLocationsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationLocationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationLocationsGet$Json(params?: {
    archived?: boolean;
  }): Observable<LocationResponseListDlmBaseResponse> {

    return this.apiLocationLocationsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseListDlmBaseResponse>) => r.body as LocationResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLocationLocationPost
   */
  static readonly ApiLocationLocationPostPath = '/api/Location/location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationLocationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationLocationPost$Plain$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationLocationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationLocationPost$Plain(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationLocationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationLocationPost$Json$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationLocationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationLocationPost$Json(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLocationEditLocationPost
   */
  static readonly ApiLocationEditLocationPostPath = '/api/Location/edit-location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationEditLocationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationEditLocationPost$Plain$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationEditLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationEditLocationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationEditLocationPost$Plain(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationEditLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationEditLocationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationEditLocationPost$Json$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationEditLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationEditLocationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationEditLocationPost$Json(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationEditLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLocationDeleteLocationPost
   */
  static readonly ApiLocationDeleteLocationPostPath = '/api/Location/delete-location';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationDeleteLocationPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationDeleteLocationPost$Plain$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationDeleteLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationDeleteLocationPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationDeleteLocationPost$Plain(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationDeleteLocationPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationDeleteLocationPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationDeleteLocationPost$Json$Response(params?: {
    body?: LocationResponse
  }): Observable<StrictHttpResponse<LocationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationDeleteLocationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LocationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationDeleteLocationPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiLocationDeleteLocationPost$Json(params?: {
    body?: LocationResponse
  }): Observable<LocationResponseDlmBaseResponse> {

    return this.apiLocationDeleteLocationPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LocationResponseDlmBaseResponse>) => r.body as LocationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiLocationRestorePost
   */
  static readonly ApiLocationRestorePostPath = '/api/Location/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiLocationRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLocationRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, LocationService.ApiLocationRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiLocationRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLocationRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiLocationRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
