import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { FilterStorageType, FilterStorage } from '../models/filters.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  private ls = window.localStorage;

  constructor() {}

  public setItem(key, value) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  public getItem(key) {
    const value = this.ls.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  public clear() {
    this.ls.clear();
  }


  public getFilters(key: string) {
    return JSON.parse(this.getItem(key));
  }

  public getFiltersValue(filterStorageType: FilterStorageType, filters: FilterStorage) {
    if (!filters?.filters) return null;

    return filters?.filters?.find((x) => x.key == filterStorageType)
      ?.value;
  }

  public addFilters(key: string, filters: FilterStorage) {
    var filtersJson = JSON.stringify(filters);
    this.setItem(key, filtersJson);
  }

  public updateFilters(
    pageKey: string,
    filter: KeyValue<FilterStorageType, string>,
    filters: FilterStorage
  ) {
    this.getFilters(pageKey);

    if (!filters) {
      // add filter storage
      this.addFilters(pageKey, { filters: [filter] });
    } else {
      var existingFilter = filters?.filters.find(
        (x) => x.key == filter?.key
      );

      if (!existingFilter) {
        // add filter keypair
        filters?.filters?.push(filter);
      } else {
        // update the existing value
        existingFilter.value = filter?.value;
      }

      this.setItem(pageKey, JSON.stringify(filters));
    }
  }
}
