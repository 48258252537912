import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-file-popup',
  templateUrl: './upload-file-popup.component.html',
  styles: [
    /* language=SCSS */
    `
      .popup {
        min-width: 30vw;//350px;
        min-height: 200px;
      }

      .upload-icon {
        font-size: 60px;
        width: 50px;
        height: 50px;
      }

      .hidden-input {
        display: none !important;
      }
    `
  ]
})
export class UploadFilePopupComponent {

  public selectedFiles: FileList;

  constructor(
    public dialogRef: MatDialogRef<UploadFilePopupComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) { }

  ngOnInit(): void {
  }

  onFileSelected(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    
    if (fileInput.files) {
      this.selectedFiles = fileInput.files;
    }
  }

  getSelectedFilesText() {
    if (this.selectedFiles) {
      return this.selectedFiles.length > 1 ? `${this.selectedFiles.length} files selected` : this.selectedFiles[0].name;
    }
    return 'No file selected';
  }

  onUpload(): void {
    this.dialogRef.close(this.selectedFiles);
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
