import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { initialDataResolver } from './app.resolvers';
import { NoAuthGuard } from './api/services/cookie-jwt-auth/guards/noAuth.guard';
import { AuthGuard } from './api/services/cookie-jwt-auth/guards/auth.guard';
import { FeatureGuard } from './api/services/cookie-jwt-auth/guards/feature-guard';
import { BasicGuard } from './api/services/cookie-jwt-auth/guards/basic-guard';
import { AssetBoardComponent } from './views/admin/erp/products/asset-board/asset-board.component';
import { UserRoleService } from './views/calibrations/shared/services/auth/user-role.service';
import { AccessGuard } from './views/calibrations/shared/guards/access.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'email-confirmed', loadChildren: () => import('app/views/auth/email-confirmed/email-confirmed.routes')},
            {path: 'forgot-password', loadChildren: () => import('app/views/auth/forgot-password/forgot-password.routes')},
            {path: 'reset-password', loadChildren: () => import('app/views/auth/reset-password/reset-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/views/auth/sign-in/sign-in.routes')},
            {path: 'sign-up', loadChildren: () => import('app/views/auth/sign-up/sign-up.routes')}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/views/auth/sign-out/sign-out.routes')},
            {path: 'unlock-session', loadChildren: () => import('app/views/auth/unlock-session/unlock-session.routes')}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', 
            loadChildren: () =>
                import("app/views/landing/home/home.module").then(
                (m) => m.HomeModule
            )},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
            // Profile
            {
                path: 'profile', loadChildren: () => //import('app/views/admin/dashboard/dashboard.routes')
                    import("app/views/admin/profile/profile.module").then(
                    (m) => m.ProfileModule
                ),
            },

            // Dashboard
            {
                path: 'dashboard', loadChildren: () => //import('app/views/admin/dashboard/dashboard.routes')
                    import("app/views/admin/dashboard/dashboard.module").then(
                    (m) => m.DashboardModule
                ),
            },

            // Hire
            {path: 'hire', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'dashboard',
                    loadChildren: () =>
                        import("app/views/admin/crm/crm-dashboard/crm-dashboard.module").then(
                        (m) => m.CrmDashboardModule
                    ),
                },
                {
                    path: 'asset-board',
                    component: AssetBoardComponent,
                },
                {path: 'customers',
                    loadChildren: () =>
                        import("app/views/admin/apps/contacts/contacts.module").then(
                        (m) => m.ContactsModule
                    ),
                },
                {path: 'calendar',
                    loadChildren: () =>
                        import("app/views/admin/apps/calendar/calendar.module").then(
                        (m) => m.CalendarModule
                    ),
                },
                {path: 'assets',
                    loadChildren: () =>
                        import("app/views/admin/erp/products/products.module").then(
                        (m) => m.ProductsModule
                    ),
                },
                {path: 'sales-orders',
                    loadChildren: () =>
                        import("app/views/admin/pages/purchase-orders/purchase-orders.module").then(
                        (m) => m.PurchaseOrdersModule
                    ),
                },
                {path: 'invoicing',
                    loadChildren: () =>
                        import("app/views/admin/pages/invoice/invoice.module").then(
                        (m) => m.InvoiceModule
                    ),
                },
            ]},

            // Project Management
            {path: 'project-management', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                {path: 'dashboard', 
                    loadChildren: () =>
                        import("app/views/admin/pages/project-dashboard/project-dashboard.module").then(
                        (m) => m.ProjectDashboardModule
                    ),
                },
                {path: 'projects', 
                    loadChildren: () =>
                        import("app/views/admin/pages/projects/projects.module").then(
                        (m) => m.ProjectsModule
                    ),
                },
                {path: 'workload', 
                    loadChildren: () =>
                        import("app/views/admin/pages/workload/workload.module").then(
                        (m) => m.WorkloadModule
                    ),
                },
            ]},


            // Calibration
            {path: 'calibration', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [

                {path: "dashboard",
                    loadChildren: () =>
                        import("./views/calibrations/views/dashboard/dashboard.module").then(
                        (m) => m.DashboardModule
                    ),
                    data: {
                        // accessLevels: UserRoleService.EVERYONE_ACCESS,
                        title: "Dashboard",
                    },
                    // canActivate: [AccessGuard],
                },
                
                {path: "servicing",
                    loadChildren: () =>
                        import("./views/calibrations/views/repairs-and-calibrations/repairs-and-calibrations.module").then(
                        (m) => m.RepairsAndCalibrationsModule),
                    data: {
                        // accessLevels: UserRoleService.EVERYONE_ACCESS,
                        title: "Servicing",
                        breadcrumb: "Servicing",
                    },
                    // canActivate: [AccessGuard],
                },
                {path: "settings",
                  loadChildren: () =>
                    import("./views/calibrations/views/settings/settings.module").then(
                      (m) => m.DLMSettingsModule
                    ),
                    data: {
                        // accessLevels: UserRoleService.EVERYONE_ACCESS,
                        title: "settings",
                    },
                    // canActivate: [AccessGuard],
                },
                {path: "form-10a",
                  loadChildren: () =>
                    import("./views/calibrations/views/form10a/form10a.module").then(
                      (m) => m.Form10aModule
                    ),
                    data: {
                        // accessLevels: UserRoleService.EVERYONE_ACCESS,
                        title: "Form 10a",
                    },
                },
                {path: "customers",
                    loadChildren: () =>
                        import("./views/calibrations/views/customers/customers.module").then(
                        (m) => m.CustomersModule
                    ),
                    data: {
                        // accessLevels: UserRoleService.EVERYONE_ACCESS,
                        title: "Customers",
                        breadcrumb: "Customers",
                    },
                    // canActivate: [AccessGuard],
                },

                // {path: 'users'},
                // {path: 'warehouse-locations'},
                // {path: 'loadcell-types'},
                // {path: 'display-types'},
                // // {path: 'sla'},
                // {path: 'parts-register'},
                // {path: 'machines'},
                // {path: 'pressure-test'},
                // {path: 'equipment'},
                // // {path: 'labour'},
                // {path: 'measurements'},
                // {path: 'packaging'},
                // {path: 'faults'},
                // {path: 'rectification'},
            ]},

            {path: 'unused', canActivate: [FeatureGuard], canActivateChild: [FeatureGuard], children: [
                // these error if not imported here - not sure why. The feature guard will prevent them from being accessible
                {path: 'pipeline',
                    loadChildren: () =>
                        import("app/views/admin/crm/pipeline/sales-pipeline.module").then(
                        (m) => m.SalesPipelineModule
                    ),
                },
                {path: 'documentations',
                    loadChildren: () =>
                        import("app/views/admin/installation/documentations/documentations.module").then(
                        (m) => m.DocumentationsModule
                    ),
                },
            ]},
            
            // Settings
            {path: 'settings', canActivate: [BasicGuard], canActivateChild: [BasicGuard],
                loadChildren: () =>
                    import("app/views/admin/pages/settings/settings.module").then(
                    (m) => m.SettingsModule
                ),
            },

            // 404 & Catch all
            {path: '**', redirectTo: '404-not-found'}
        ]
    }
];
