/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { PartOptionListDlmBaseResponse } from '../models/part-option-list-dlm-base-response';
import { PartResponse } from '../models/part-response';
import { PartResponseDlmBaseResponse } from '../models/part-response-dlm-base-response';
import { PartResponseListDlmBaseResponse } from '../models/part-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class PartService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPartPartsGet
   */
  static readonly ApiPartPartsGetPath = '/api/Part/parts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartPartsGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PartResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartPartsGet$Plain(params?: {
    archived?: boolean;
  }): Observable<PartResponseListDlmBaseResponse> {

    return this.apiPartPartsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseListDlmBaseResponse>) => r.body as PartResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartPartsGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<PartResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartPartsGet$Json(params?: {
    archived?: boolean;
  }): Observable<PartResponseListDlmBaseResponse> {

    return this.apiPartPartsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseListDlmBaseResponse>) => r.body as PartResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPartFilteredPartsGet
   */
  static readonly ApiPartFilteredPartsGetPath = '/api/Part/filtered-parts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartFilteredPartsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartFilteredPartsGet$Plain$Response(params?: {
    filterText?: string;
    searchByDescription?: boolean;
  }): Observable<StrictHttpResponse<PartOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartFilteredPartsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
      rb.query('searchByDescription', params.searchByDescription, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartFilteredPartsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartFilteredPartsGet$Plain(params?: {
    filterText?: string;
    searchByDescription?: boolean;
  }): Observable<PartOptionListDlmBaseResponse> {

    return this.apiPartFilteredPartsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PartOptionListDlmBaseResponse>) => r.body as PartOptionListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartFilteredPartsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartFilteredPartsGet$Json$Response(params?: {
    filterText?: string;
    searchByDescription?: boolean;
  }): Observable<StrictHttpResponse<PartOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartFilteredPartsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
      rb.query('searchByDescription', params.searchByDescription, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartFilteredPartsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartFilteredPartsGet$Json(params?: {
    filterText?: string;
    searchByDescription?: boolean;
  }): Observable<PartOptionListDlmBaseResponse> {

    return this.apiPartFilteredPartsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PartOptionListDlmBaseResponse>) => r.body as PartOptionListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPartPartPut
   */
  static readonly ApiPartPartPutPath = '/api/Part/part';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPut$Plain$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPut$Plain(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPut$Json$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPut$Json(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPartPartPost
   */
  static readonly ApiPartPartPostPath = '/api/Part/part';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPost$Plain$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPost$Plain(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPost$Json$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartPost$Json(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPartPartDelete
   */
  static readonly ApiPartPartDeletePath = '/api/Part/part';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartDelete$Plain$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartDelete$Plain(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartPartDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartDelete$Json$Response(params?: {
    body?: PartResponse
  }): Observable<StrictHttpResponse<PartResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartPartDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartPartDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPartPartDelete$Json(params?: {
    body?: PartResponse
  }): Observable<PartResponseDlmBaseResponse> {

    return this.apiPartPartDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PartResponseDlmBaseResponse>) => r.body as PartResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiPartRestorePost
   */
  static readonly ApiPartRestorePostPath = '/api/Part/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPartRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPartRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PartService.ApiPartRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPartRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPartRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiPartRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
