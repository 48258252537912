/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { ExaminationFaultOptionListDlmBaseResponse } from '../models/examination-fault-option-list-dlm-base-response';
import { ExaminationFaultResponse } from '../models/examination-fault-response';
import { ExaminationFaultResponseDlmBaseResponse } from '../models/examination-fault-response-dlm-base-response';
import { ExaminationFaultResponseListDlmBaseResponse } from '../models/examination-fault-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class ExaminationFaultService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiExaminationFaultExaminationFaultsGet
   */
  static readonly ApiExaminationFaultExaminationFaultsGetPath = '/api/ExaminationFault/examination-faults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultExaminationFaultsGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultExaminationFaultsGet$Plain(params?: {
    archived?: boolean;
  }): Observable<ExaminationFaultResponseListDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>) => r.body as ExaminationFaultResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultExaminationFaultsGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultsGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultExaminationFaultsGet$Json(params?: {
    archived?: boolean;
  }): Observable<ExaminationFaultResponseListDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseListDlmBaseResponse>) => r.body as ExaminationFaultResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiExaminationFaultFilteredExaminationFaultsGet
   */
  static readonly ApiExaminationFaultFilteredExaminationFaultsGetPath = '/api/ExaminationFault/filtered-examination-faults';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultFilteredExaminationFaultsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultFilteredExaminationFaultsGet$Plain$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultFilteredExaminationFaultsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultFilteredExaminationFaultsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultFilteredExaminationFaultsGet$Plain(params?: {
    filterText?: string;
  }): Observable<ExaminationFaultOptionListDlmBaseResponse> {

    return this.apiExaminationFaultFilteredExaminationFaultsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>) => r.body as ExaminationFaultOptionListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultFilteredExaminationFaultsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultFilteredExaminationFaultsGet$Json$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultFilteredExaminationFaultsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultFilteredExaminationFaultsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultFilteredExaminationFaultsGet$Json(params?: {
    filterText?: string;
  }): Observable<ExaminationFaultOptionListDlmBaseResponse> {

    return this.apiExaminationFaultFilteredExaminationFaultsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultOptionListDlmBaseResponse>) => r.body as ExaminationFaultOptionListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiExaminationFaultExaminationFaultPut
   */
  static readonly ApiExaminationFaultExaminationFaultPutPath = '/api/ExaminationFault/examination-fault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPut$Plain$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPut$Plain(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPut$Json$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPut$Json(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiExaminationFaultExaminationFaultPost
   */
  static readonly ApiExaminationFaultExaminationFaultPostPath = '/api/ExaminationFault/examination-fault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPost$Plain$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPost$Plain(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPost$Json$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultPost$Json(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiExaminationFaultExaminationFaultDelete
   */
  static readonly ApiExaminationFaultExaminationFaultDeletePath = '/api/ExaminationFault/examination-fault';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultDelete$Plain$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultDelete$Plain(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultExaminationFaultDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultDelete$Json$Response(params?: {
    body?: ExaminationFaultResponse
  }): Observable<StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultExaminationFaultDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultExaminationFaultDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiExaminationFaultExaminationFaultDelete$Json(params?: {
    body?: ExaminationFaultResponse
  }): Observable<ExaminationFaultResponseDlmBaseResponse> {

    return this.apiExaminationFaultExaminationFaultDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<ExaminationFaultResponseDlmBaseResponse>) => r.body as ExaminationFaultResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiExaminationFaultRestorePost
   */
  static readonly ApiExaminationFaultRestorePostPath = '/api/ExaminationFault/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiExaminationFaultRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExaminationFaultRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ExaminationFaultService.ApiExaminationFaultRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiExaminationFaultRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiExaminationFaultRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiExaminationFaultRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
