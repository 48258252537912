<div class="flex flex-col w-full min-w-0 sm:absolute sm:inset-0 sm:overflow-hidden">
    <mat-drawer-container class="flex-auto sm:h-full">
        <mat-drawer-content class="flex flex-col" style="background-color: #F8F8F8;">

            <!-- Main -->
            <div class="flex-auto px-6 pt-9 pb-12 md:p-8 md:pb-12 lg:p-12">

                <!-- Panel header -->
                <div class="flex items-center relative z-50 justify-between">
                    <!-- Panel title -->
                    <div class="ml-2 lg:ml-0 text-3xl font-bold tracking-tight leading-none">
                        Asset Board
                    </div>

                    <!-- Actions -->
                    <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                        <!-- Select Status -->
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-rounded min-w-48 ml-4"
                            [subscriptSizing]="'dynamic'">
                            <mat-select
                                class="wide-select"
                                [value]="selectedStatusId"
                                (selectionChange)="filterStatus($event)">
                                <mat-select-trigger>
                                    <span class="font-medium">{{ getStatusFilterText(selectedStatusId) | titlecase }}</span>
                                </mat-select-trigger>
                                <mat-option
                                    class="h-auto py-4 leading-none"
                                    [value]="0">
                                    <div>
                                        All
                                    </div>
                                </mat-option>
                                <ng-container *ngFor="let status of statuses">
                                    <mat-option
                                        class="h-auto py-4 leading-none"
                                        [value]="status.productCategoryId">
                                        <div>
                                            {{ status.title }}
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                        <!-- Search -->
                        <mat-form-field
                            class="fuse-mat-dense fuse-mat-rounded min-w-64 ml-4"
                            subscriptSizing="dynamic"
                        >
                            <mat-icon
                                class="icon-size-5"
                                matPrefix
                                [svgIcon]="'heroicons_solid:magnifying-glass'">
                            </mat-icon>
                            <input
                                matInput
                                (change)="search($event)"
                                [autocomplete]="'off'"
                                [placeholder]="'Search Assets'"
                            >
                        </mat-form-field>
                    </div>



                </div>

                <!-- Load settings panel -->
                <div class="mt-8">
                    <scrumboard-board #assetBoard [isAssetBoard]="true" [searchTerm]="searchTerm" [selectedStatusId]="queryStatusId"></scrumboard-board><!-- [projectId]="4"  -->
                </div>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
