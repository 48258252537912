/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FormSalesOrder } from '../models/form-sales-order';
import { FormSalesOrderDlmBaseResponse } from '../models/form-sales-order-dlm-base-response';
import { FormSalesOrderListDlmBaseResponse } from '../models/form-sales-order-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class SalesOrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSalesOrderSalesOrdersGet
   */
  static readonly ApiSalesOrderSalesOrdersGetPath = '/api/SalesOrder/sales-orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderSalesOrdersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderSalesOrdersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderSalesOrdersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderSalesOrdersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderSalesOrdersGet$Plain(params?: {
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderSalesOrdersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderSalesOrdersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderSalesOrdersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderSalesOrdersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderSalesOrdersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderSalesOrdersGet$Json(params?: {
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderSalesOrdersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiSalesOrderFilteredSalesOrdersGet
   */
  static readonly ApiSalesOrderFilteredSalesOrdersGetPath = '/api/SalesOrder/filtered-sales-orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderFilteredSalesOrdersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredSalesOrdersGet$Plain$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderFilteredSalesOrdersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderFilteredSalesOrdersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredSalesOrdersGet$Plain(params?: {
    filterText?: string;
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderFilteredSalesOrdersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderFilteredSalesOrdersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredSalesOrdersGet$Json$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderFilteredSalesOrdersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderFilteredSalesOrdersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredSalesOrdersGet$Json(params?: {
    filterText?: string;
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderFilteredSalesOrdersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiSalesOrderFilteredCustomerSalesOrdersGet
   */
  static readonly ApiSalesOrderFilteredCustomerSalesOrdersGetPath = '/api/SalesOrder/filtered-customer-sales-orders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderFilteredCustomerSalesOrdersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredCustomerSalesOrdersGet$Plain$Response(params?: {
    customerId?: number;
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderFilteredCustomerSalesOrdersGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderFilteredCustomerSalesOrdersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredCustomerSalesOrdersGet$Plain(params?: {
    customerId?: number;
    filterText?: string;
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderFilteredCustomerSalesOrdersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderFilteredCustomerSalesOrdersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredCustomerSalesOrdersGet$Json$Response(params?: {
    customerId?: number;
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSalesOrderListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderFilteredCustomerSalesOrdersGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderFilteredCustomerSalesOrdersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSalesOrderFilteredCustomerSalesOrdersGet$Json(params?: {
    customerId?: number;
    filterText?: string;
  }): Observable<FormSalesOrderListDlmBaseResponse> {

    return this.apiSalesOrderFilteredCustomerSalesOrdersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderListDlmBaseResponse>) => r.body as FormSalesOrderListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiSalesOrderSalesOrderPost
   */
  static readonly ApiSalesOrderSalesOrderPostPath = '/api/SalesOrder/sales-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderSalesOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSalesOrderSalesOrderPost$Plain$Response(params?: {
    body?: FormSalesOrder
  }): Observable<StrictHttpResponse<FormSalesOrderDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderSalesOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderSalesOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSalesOrderSalesOrderPost$Plain(params?: {
    body?: FormSalesOrder
  }): Observable<FormSalesOrderDlmBaseResponse> {

    return this.apiSalesOrderSalesOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderDlmBaseResponse>) => r.body as FormSalesOrderDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSalesOrderSalesOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSalesOrderSalesOrderPost$Json$Response(params?: {
    body?: FormSalesOrder
  }): Observable<StrictHttpResponse<FormSalesOrderDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SalesOrderService.ApiSalesOrderSalesOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSalesOrderDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSalesOrderSalesOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSalesOrderSalesOrderPost$Json(params?: {
    body?: FormSalesOrder
  }): Observable<FormSalesOrderDlmBaseResponse> {

    return this.apiSalesOrderSalesOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSalesOrderDlmBaseResponse>) => r.body as FormSalesOrderDlmBaseResponse)
    );
  }

}
