/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { RectificationWorkOptionListDlmBaseResponse } from '../models/rectification-work-option-list-dlm-base-response';
import { RectificationWorkResponse } from '../models/rectification-work-response';
import { RectificationWorkResponseDlmBaseResponse } from '../models/rectification-work-response-dlm-base-response';
import { RectificationWorkResponseListDlmBaseResponse } from '../models/rectification-work-response-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class RectificationWorkService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRectificationWorkRectificationWorksGet
   */
  static readonly ApiRectificationWorkRectificationWorksGetPath = '/api/RectificationWork/rectification-works';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorksGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRectificationWorksGet$Plain$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorksGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorksGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRectificationWorksGet$Plain(params?: {
    archived?: boolean;
  }): Observable<RectificationWorkResponseListDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>) => r.body as RectificationWorkResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRectificationWorksGet$Json$Response(params?: {
    archived?: boolean;
  }): Observable<StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorksGetPath, 'get');
    if (params) {
      rb.query('archived', params.archived, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRectificationWorksGet$Json(params?: {
    archived?: boolean;
  }): Observable<RectificationWorkResponseListDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseListDlmBaseResponse>) => r.body as RectificationWorkResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRectificationWorkFilteredRectificationWorksGet
   */
  static readonly ApiRectificationWorkFilteredRectificationWorksGetPath = '/api/RectificationWork/filtered-rectification-works';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkFilteredRectificationWorksGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkFilteredRectificationWorksGet$Plain$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkFilteredRectificationWorksGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkFilteredRectificationWorksGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkFilteredRectificationWorksGet$Plain(params?: {
    filterText?: string;
  }): Observable<RectificationWorkOptionListDlmBaseResponse> {

    return this.apiRectificationWorkFilteredRectificationWorksGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>) => r.body as RectificationWorkOptionListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkFilteredRectificationWorksGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkFilteredRectificationWorksGet$Json$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkFilteredRectificationWorksGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkFilteredRectificationWorksGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkFilteredRectificationWorksGet$Json(params?: {
    filterText?: string;
  }): Observable<RectificationWorkOptionListDlmBaseResponse> {

    return this.apiRectificationWorkFilteredRectificationWorksGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkOptionListDlmBaseResponse>) => r.body as RectificationWorkOptionListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRectificationWorkRectificationWorkPut
   */
  static readonly ApiRectificationWorkRectificationWorkPutPath = '/api/RectificationWork/rectification-work';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkPut$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPut$Plain$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkPut$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPut$Plain(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkPut$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkPut$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPut$Json$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkPut$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPut$Json(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkPut$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRectificationWorkRectificationWorkPost
   */
  static readonly ApiRectificationWorkRectificationWorkPostPath = '/api/RectificationWork/rectification-work';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPost$Plain$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPost$Plain(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPost$Json$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkPost$Json(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRectificationWorkRectificationWorkDelete
   */
  static readonly ApiRectificationWorkRectificationWorkDeletePath = '/api/RectificationWork/rectification-work';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkDelete$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkDelete$Plain$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkDelete$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkDelete$Plain(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkDelete$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRectificationWorkDelete$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkDelete$Json$Response(params?: {
    body?: RectificationWorkResponse
  }): Observable<StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRectificationWorkDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRectificationWorkDelete$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRectificationWorkRectificationWorkDelete$Json(params?: {
    body?: RectificationWorkResponse
  }): Observable<RectificationWorkResponseDlmBaseResponse> {

    return this.apiRectificationWorkRectificationWorkDelete$Json$Response(params).pipe(
      map((r: StrictHttpResponse<RectificationWorkResponseDlmBaseResponse>) => r.body as RectificationWorkResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRectificationWorkRestorePost
   */
  static readonly ApiRectificationWorkRestorePostPath = '/api/RectificationWork/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRectificationWorkRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRectificationWorkRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RectificationWorkService.ApiRectificationWorkRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRectificationWorkRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRectificationWorkRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRectificationWorkRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
