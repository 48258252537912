export class ColourUtil {

    public static getRandomBgColour() {
        const lowest = 150;
        const highest = 250;

        var arr = [0,0,0];
        return arr.reduce((prev, _) => prev + (Math.floor(Math.random() * (highest-lowest)) + lowest).toString(16).padStart(2, '0'), "#");
    }

    public static getRandomMidColour() {
        const lowest = 75;//100;
        const highest = 250;//210;

        var arr = [0,0,0].map(_ => Math.floor(Math.random() * (highest-lowest)) + lowest);

        var sum = arr.reduce((p, c) => p + c, 0);

        if(Math.max(...arr) - Math.min(...arr) < 30 || sum < 250 || sum > 450) //too grey/dark/light - reroll colours
            return this.getRandomMidColour();

        return arr.reduce((prev, cur) => prev + cur.toString(16).padStart(2, '0'), "#");
    }

    public static getContrastColor(hexColor) {
        // If the color is not in hex format, return default color
        if (!/^#([0-9A-F]{3}){1,2}$/i.test(hexColor)) {
            return '#000000'; // Default color
        }
    
        let r = parseInt(hexColor.substr(1, 2), 16); // Red color
        let g = parseInt(hexColor.substr(3, 2), 16); // Green color
        let b = parseInt(hexColor.substr(5, 2), 16); // Blue color
    
        // Calculate the brightness of the color
        let brightness = (r * 299 + g * 587 + b * 114) / 1000;
    
        // If the brightness is greater than 128, return black color, else return white color
        return brightness > 128 ? '#000000' : '#FFFFFF';
    }
}
