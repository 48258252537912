/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DecimalDlmBaseResponse } from '../models/decimal-dlm-base-response';
import { DecimalNullableDlmBaseResponse } from '../models/decimal-nullable-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class MarkupService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMarkupMarkupGet
   */
  static readonly ApiMarkupMarkupGetPath = '/api/Markup/markup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMarkupMarkupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<DecimalDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MarkupService.ApiMarkupMarkupGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMarkupMarkupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupGet$Plain(params?: {
  }): Observable<DecimalDlmBaseResponse> {

    return this.apiMarkupMarkupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalDlmBaseResponse>) => r.body as DecimalDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMarkupMarkupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<DecimalDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MarkupService.ApiMarkupMarkupGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMarkupMarkupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupGet$Json(params?: {
  }): Observable<DecimalDlmBaseResponse> {

    return this.apiMarkupMarkupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalDlmBaseResponse>) => r.body as DecimalDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiMarkupMarkupPost
   */
  static readonly ApiMarkupMarkupPostPath = '/api/Markup/markup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMarkupMarkupPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupPost$Plain$Response(params?: {
    markup?: number;
  }): Observable<StrictHttpResponse<DecimalNullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MarkupService.ApiMarkupMarkupPostPath, 'post');
    if (params) {
      rb.query('markup', params.markup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalNullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMarkupMarkupPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupPost$Plain(params?: {
    markup?: number;
  }): Observable<DecimalNullableDlmBaseResponse> {

    return this.apiMarkupMarkupPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalNullableDlmBaseResponse>) => r.body as DecimalNullableDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMarkupMarkupPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupPost$Json$Response(params?: {
    markup?: number;
  }): Observable<StrictHttpResponse<DecimalNullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MarkupService.ApiMarkupMarkupPostPath, 'post');
    if (params) {
      rb.query('markup', params.markup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DecimalNullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMarkupMarkupPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMarkupMarkupPost$Json(params?: {
    markup?: number;
  }): Observable<DecimalNullableDlmBaseResponse> {

    return this.apiMarkupMarkupPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<DecimalNullableDlmBaseResponse>) => r.body as DecimalNullableDlmBaseResponse)
    );
  }

}
