/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FormSerialNumber } from '../models/form-serial-number';
import { FormSerialNumberDlmBaseResponse } from '../models/form-serial-number-dlm-base-response';
import { FormSerialNumberListDlmBaseResponse } from '../models/form-serial-number-list-dlm-base-response';

@Injectable({
  providedIn: 'root',
})
export class SerialNumberService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSerialNumberSerialNumbersGet
   */
  static readonly ApiSerialNumberSerialNumbersGetPath = '/api/SerialNumber/serial-numbers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberSerialNumbersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberSerialNumbersGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<FormSerialNumberListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberSerialNumbersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberSerialNumbersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberSerialNumbersGet$Plain(params?: {
  }): Observable<FormSerialNumberListDlmBaseResponse> {

    return this.apiSerialNumberSerialNumbersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberListDlmBaseResponse>) => r.body as FormSerialNumberListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberSerialNumbersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberSerialNumbersGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<FormSerialNumberListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberSerialNumbersGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberSerialNumbersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberSerialNumbersGet$Json(params?: {
  }): Observable<FormSerialNumberListDlmBaseResponse> {

    return this.apiSerialNumberSerialNumbersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberListDlmBaseResponse>) => r.body as FormSerialNumberListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiSerialNumberFilteredSerialNumbersGet
   */
  static readonly ApiSerialNumberFilteredSerialNumbersGetPath = '/api/SerialNumber/filtered-serial-numbers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberFilteredSerialNumbersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberFilteredSerialNumbersGet$Plain$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSerialNumberListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberFilteredSerialNumbersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberFilteredSerialNumbersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberFilteredSerialNumbersGet$Plain(params?: {
    filterText?: string;
  }): Observable<FormSerialNumberListDlmBaseResponse> {

    return this.apiSerialNumberFilteredSerialNumbersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberListDlmBaseResponse>) => r.body as FormSerialNumberListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberFilteredSerialNumbersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberFilteredSerialNumbersGet$Json$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormSerialNumberListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberFilteredSerialNumbersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberFilteredSerialNumbersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSerialNumberFilteredSerialNumbersGet$Json(params?: {
    filterText?: string;
  }): Observable<FormSerialNumberListDlmBaseResponse> {

    return this.apiSerialNumberFilteredSerialNumbersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberListDlmBaseResponse>) => r.body as FormSerialNumberListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiSerialNumberSerialNumberPost
   */
  static readonly ApiSerialNumberSerialNumberPostPath = '/api/SerialNumber/serial-number';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberSerialNumberPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSerialNumberSerialNumberPost$Plain$Response(params?: {
    body?: FormSerialNumber
  }): Observable<StrictHttpResponse<FormSerialNumberDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberSerialNumberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberSerialNumberPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSerialNumberSerialNumberPost$Plain(params?: {
    body?: FormSerialNumber
  }): Observable<FormSerialNumberDlmBaseResponse> {

    return this.apiSerialNumberSerialNumberPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberDlmBaseResponse>) => r.body as FormSerialNumberDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSerialNumberSerialNumberPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSerialNumberSerialNumberPost$Json$Response(params?: {
    body?: FormSerialNumber
  }): Observable<StrictHttpResponse<FormSerialNumberDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, SerialNumberService.ApiSerialNumberSerialNumberPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormSerialNumberDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSerialNumberSerialNumberPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSerialNumberSerialNumberPost$Json(params?: {
    body?: FormSerialNumber
  }): Observable<FormSerialNumberDlmBaseResponse> {

    return this.apiSerialNumberSerialNumberPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormSerialNumberDlmBaseResponse>) => r.body as FormSerialNumberDlmBaseResponse)
    );
  }

}
