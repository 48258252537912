/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse } from '../models/customer-loadcell-response-dlm-paged-response-dlm-base-response';
import { CustomerLoadcellResponseListDlmBaseResponse } from '../models/customer-loadcell-response-list-dlm-base-response';
import { CustomerLoadcellSortBy } from '../models/customer-loadcell-sort-by';
import { CustomerResponse } from '../models/customer-response';
import { CustomerResponseDlmBaseResponse } from '../models/customer-response-dlm-base-response';
import { CustomerResponseDlmPagedResponseDlmBaseResponse } from '../models/customer-response-dlm-paged-response-dlm-base-response';
import { CustomerSortBy } from '../models/customer-sort-by';
import { FormCustomerListDlmBaseResponse } from '../models/form-customer-list-dlm-base-response';
import { Int64ListDlmBaseResponse } from '../models/int-64-list-dlm-base-response';
import { OrderDirection } from '../models/order-direction';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiCustomerGetCustomersGet
   */
  static readonly ApiCustomerGetCustomersGetPath = '/api/Customer/get-customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerGetCustomersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerGetCustomersGet$Plain$Response(params?: {

    /**
     * 0 &#x3D; Name
     * 
     * 1 &#x3D; Address
     * 
     * 2 &#x3D; Postcode
     * 
     * 3 &#x3D; TelephoneNo
     */
    Sort?: CustomerSortBy;
    IsDeleted?: boolean;
    LoadcellSearch?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerGetCustomersGetPath, 'get');
    if (params) {
      rb.query('Sort', params.Sort, {});
      rb.query('IsDeleted', params.IsDeleted, {});
      rb.query('LoadcellSearch', params.LoadcellSearch, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerGetCustomersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerGetCustomersGet$Plain(params?: {

    /**
     * 0 &#x3D; Name
     * 
     * 1 &#x3D; Address
     * 
     * 2 &#x3D; Postcode
     * 
     * 3 &#x3D; TelephoneNo
     */
    Sort?: CustomerSortBy;
    IsDeleted?: boolean;
    LoadcellSearch?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CustomerResponseDlmPagedResponseDlmBaseResponse> {

    return this.apiCustomerGetCustomersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>) => r.body as CustomerResponseDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerGetCustomersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerGetCustomersGet$Json$Response(params?: {

    /**
     * 0 &#x3D; Name
     * 
     * 1 &#x3D; Address
     * 
     * 2 &#x3D; Postcode
     * 
     * 3 &#x3D; TelephoneNo
     */
    Sort?: CustomerSortBy;
    IsDeleted?: boolean;
    LoadcellSearch?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerGetCustomersGetPath, 'get');
    if (params) {
      rb.query('Sort', params.Sort, {});
      rb.query('IsDeleted', params.IsDeleted, {});
      rb.query('LoadcellSearch', params.LoadcellSearch, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerGetCustomersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerGetCustomersGet$Json(params?: {

    /**
     * 0 &#x3D; Name
     * 
     * 1 &#x3D; Address
     * 
     * 2 &#x3D; Postcode
     * 
     * 3 &#x3D; TelephoneNo
     */
    Sort?: CustomerSortBy;
    IsDeleted?: boolean;
    LoadcellSearch?: string;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CustomerResponseDlmPagedResponseDlmBaseResponse> {

    return this.apiCustomerGetCustomersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmPagedResponseDlmBaseResponse>) => r.body as CustomerResponseDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerCustomerGet
   */
  static readonly ApiCustomerCustomerGetPath = '/api/Customer/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerGet$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<CustomerResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerGet$Plain(params?: {
    id?: number;
  }): Observable<CustomerResponseDlmBaseResponse> {

    return this.apiCustomerCustomerGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmBaseResponse>) => r.body as CustomerResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerGet$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<CustomerResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerGetPath, 'get');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerGet$Json(params?: {
    id?: number;
  }): Observable<CustomerResponseDlmBaseResponse> {

    return this.apiCustomerCustomerGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmBaseResponse>) => r.body as CustomerResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerCustomerPost
   */
  static readonly ApiCustomerCustomerPostPath = '/api/Customer/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerCustomerPost$Plain$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerCustomerPost$Plain(params?: {
    body?: CustomerResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerCustomerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerCustomerPost$Json$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerCustomerPost$Json(params?: {
    body?: CustomerResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerCustomerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerFormFilteredCustomersGet
   */
  static readonly ApiCustomerFormFilteredCustomersGetPath = '/api/Customer/form-filtered-customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerFormFilteredCustomersGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerFormFilteredCustomersGet$Plain$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormCustomerListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerFormFilteredCustomersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormCustomerListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerFormFilteredCustomersGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerFormFilteredCustomersGet$Plain(params?: {
    filterText?: string;
  }): Observable<FormCustomerListDlmBaseResponse> {

    return this.apiCustomerFormFilteredCustomersGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<FormCustomerListDlmBaseResponse>) => r.body as FormCustomerListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerFormFilteredCustomersGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerFormFilteredCustomersGet$Json$Response(params?: {
    filterText?: string;
  }): Observable<StrictHttpResponse<FormCustomerListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerFormFilteredCustomersGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FormCustomerListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerFormFilteredCustomersGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerFormFilteredCustomersGet$Json(params?: {
    filterText?: string;
  }): Observable<FormCustomerListDlmBaseResponse> {

    return this.apiCustomerFormFilteredCustomersGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<FormCustomerListDlmBaseResponse>) => r.body as FormCustomerListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerCustomerLoadcellsGet
   */
  static readonly ApiCustomerCustomerLoadcellsGetPath = '/api/Customer/customer-loadcells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerLoadcellsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerLoadcellsGet$Plain$Response(params?: {
    CustomerId?: number;

    /**
     * 0 &#x3D; LoadcellId
     * 
     * 1 &#x3D; SerialNumber
     * 
     * 2 &#x3D; Status
     * 
     * 3 &#x3D; Priority
     */
    Sort?: CustomerLoadcellSortBy;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerLoadcellsGetPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Sort', params.Sort, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerLoadcellsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerLoadcellsGet$Plain(params?: {
    CustomerId?: number;

    /**
     * 0 &#x3D; LoadcellId
     * 
     * 1 &#x3D; SerialNumber
     * 
     * 2 &#x3D; Status
     * 
     * 3 &#x3D; Priority
     */
    Sort?: CustomerLoadcellSortBy;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse> {

    return this.apiCustomerCustomerLoadcellsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>) => r.body as CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerLoadcellsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerLoadcellsGet$Json$Response(params?: {
    CustomerId?: number;

    /**
     * 0 &#x3D; LoadcellId
     * 
     * 1 &#x3D; SerialNumber
     * 
     * 2 &#x3D; Status
     * 
     * 3 &#x3D; Priority
     */
    Sort?: CustomerLoadcellSortBy;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerLoadcellsGetPath, 'get');
    if (params) {
      rb.query('CustomerId', params.CustomerId, {});
      rb.query('Sort', params.Sort, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerLoadcellsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerLoadcellsGet$Json(params?: {
    CustomerId?: number;

    /**
     * 0 &#x3D; LoadcellId
     * 
     * 1 &#x3D; SerialNumber
     * 
     * 2 &#x3D; Status
     * 
     * 3 &#x3D; Priority
     */
    Sort?: CustomerLoadcellSortBy;
    PageIndex?: number;
    PageSize?: number;
    SearchTerm?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse> {

    return this.apiCustomerCustomerLoadcellsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse>) => r.body as CustomerLoadcellResponseDlmPagedResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerUnquotedCustomerLoadcellsGet
   */
  static readonly ApiCustomerUnquotedCustomerLoadcellsGetPath = '/api/Customer/unquoted-customer-loadcells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerUnquotedCustomerLoadcellsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerUnquotedCustomerLoadcellsGet$Plain$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerUnquotedCustomerLoadcellsGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerUnquotedCustomerLoadcellsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerUnquotedCustomerLoadcellsGet$Plain(params?: {
    customerId?: number;
  }): Observable<CustomerLoadcellResponseListDlmBaseResponse> {

    return this.apiCustomerUnquotedCustomerLoadcellsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>) => r.body as CustomerLoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerUnquotedCustomerLoadcellsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerUnquotedCustomerLoadcellsGet$Json$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerUnquotedCustomerLoadcellsGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerUnquotedCustomerLoadcellsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerUnquotedCustomerLoadcellsGet$Json(params?: {
    customerId?: number;
  }): Observable<CustomerLoadcellResponseListDlmBaseResponse> {

    return this.apiCustomerUnquotedCustomerLoadcellsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerLoadcellResponseListDlmBaseResponse>) => r.body as CustomerLoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerCustomerPressureLoadcellsGet
   */
  static readonly ApiCustomerCustomerPressureLoadcellsGetPath = '/api/Customer/customer-pressure-loadcells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerPressureLoadcellsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerPressureLoadcellsGet$Plain$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Int64ListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerPressureLoadcellsGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerPressureLoadcellsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerPressureLoadcellsGet$Plain(params?: {
    customerId?: number;
  }): Observable<Int64ListDlmBaseResponse> {

    return this.apiCustomerCustomerPressureLoadcellsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ListDlmBaseResponse>) => r.body as Int64ListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerCustomerPressureLoadcellsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerPressureLoadcellsGet$Json$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Int64ListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerCustomerPressureLoadcellsGetPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Int64ListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerCustomerPressureLoadcellsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerCustomerPressureLoadcellsGet$Json(params?: {
    customerId?: number;
  }): Observable<Int64ListDlmBaseResponse> {

    return this.apiCustomerCustomerPressureLoadcellsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Int64ListDlmBaseResponse>) => r.body as Int64ListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerEditCustomerPost
   */
  static readonly ApiCustomerEditCustomerPostPath = '/api/Customer/edit-customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerEditCustomerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerEditCustomerPost$Plain$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerEditCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerEditCustomerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerEditCustomerPost$Plain(params?: {
    body?: CustomerResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerEditCustomerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerEditCustomerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerEditCustomerPost$Json$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerEditCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerEditCustomerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerEditCustomerPost$Json(params?: {
    body?: CustomerResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerEditCustomerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerDeleteCustomerPost
   */
  static readonly ApiCustomerDeleteCustomerPostPath = '/api/Customer/delete-customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerDeleteCustomerPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerDeleteCustomerPost$Plain$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<CustomerResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerDeleteCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerDeleteCustomerPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerDeleteCustomerPost$Plain(params?: {
    body?: CustomerResponse
  }): Observable<CustomerResponseDlmBaseResponse> {

    return this.apiCustomerDeleteCustomerPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmBaseResponse>) => r.body as CustomerResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerDeleteCustomerPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerDeleteCustomerPost$Json$Response(params?: {
    body?: CustomerResponse
  }): Observable<StrictHttpResponse<CustomerResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerDeleteCustomerPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerDeleteCustomerPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerDeleteCustomerPost$Json(params?: {
    body?: CustomerResponse
  }): Observable<CustomerResponseDlmBaseResponse> {

    return this.apiCustomerDeleteCustomerPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerResponseDlmBaseResponse>) => r.body as CustomerResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerDeleteLoadcellPost
   */
  static readonly ApiCustomerDeleteLoadcellPostPath = '/api/Customer/delete-loadcell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerDeleteLoadcellPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerDeleteLoadcellPost$Plain$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerDeleteLoadcellPostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerDeleteLoadcellPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerDeleteLoadcellPost$Plain(params?: {
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerDeleteLoadcellPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerDeleteLoadcellPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerDeleteLoadcellPost$Json$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerDeleteLoadcellPostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerDeleteLoadcellPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerDeleteLoadcellPost$Json(params?: {
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerDeleteLoadcellPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerRestorePost
   */
  static readonly ApiCustomerRestorePostPath = '/api/Customer/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerRestorePost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerRestorePost$Plain(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerRestorePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerRestorePost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerRestorePostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerRestorePost$Json(params?: {
    id?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerRestorePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerImportCustomerCsvPost
   */
  static readonly ApiCustomerImportCustomerCsvPostPath = '/api/Customer/import-customer-csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportCustomerCsvPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportCustomerCsvPost$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportCustomerCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportCustomerCsvPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportCustomerCsvPost$Plain(params?: {
    body?: string
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportCustomerCsvPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportCustomerCsvPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportCustomerCsvPost$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportCustomerCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportCustomerCsvPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportCustomerCsvPost$Json(params?: {
    body?: string
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportCustomerCsvPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerImportContactCsvPost
   */
  static readonly ApiCustomerImportContactCsvPostPath = '/api/Customer/import-contact-csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportContactCsvPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportContactCsvPost$Plain$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportContactCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportContactCsvPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportContactCsvPost$Plain(params?: {
    body?: string
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportContactCsvPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportContactCsvPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportContactCsvPost$Json$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportContactCsvPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportContactCsvPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCustomerImportContactCsvPost$Json(params?: {
    body?: string
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportContactCsvPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiCustomerImportFixPrimaryContactsPost
   */
  static readonly ApiCustomerImportFixPrimaryContactsPostPath = '/api/Customer/import-fix-primary-contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportFixPrimaryContactsPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerImportFixPrimaryContactsPost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportFixPrimaryContactsPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportFixPrimaryContactsPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerImportFixPrimaryContactsPost$Plain(params?: {
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportFixPrimaryContactsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCustomerImportFixPrimaryContactsPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerImportFixPrimaryContactsPost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerService.ApiCustomerImportFixPrimaryContactsPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiCustomerImportFixPrimaryContactsPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCustomerImportFixPrimaryContactsPost$Json(params?: {
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiCustomerImportFixPrimaryContactsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
