<div style="width: 25vw;">

    <!-- Form -->
    <form *ngIf="form" [formGroup]="form">

        <!-- Header -->
        <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary" style="margin: -24px;">
            <div class="text-lg font-medium">{{title}}</div>
            <button
                mat-icon-button
                (click)="close()"
                [tabIndex]="-1">
                <mat-icon
                    class="text-current"
                    [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
            </button>
        </div>
        
        <div class="grid sm:grid-cols-4 gap-6 w-full mt-12">
            <div class="sm:col-span-4 grid sm:grid-cols-4 gap-6 w-full mt-2">
                <!-- Hours -->
                <div class="sm:col-span-2">
                    <mat-form-field
                        class="w-full"
                        [subscriptSizing]="'dynamic'">
                        <mat-label>Hours</mat-label>
                        <input
                            formControlName="hours"
                            matInput
                            type="number"
                            (input)="forceInteger($event)">
                    </mat-form-field>
                </div>
                <!-- Minutes -->
                <div class="sm:col-span-2">
                    <mat-form-field
                        class="w-full"
                        [subscriptSizing]="'dynamic'">
                        <mat-label>Minutes</mat-label>
                        <input
                            formControlName="minutes"
                            matInput
                            type="number"
                            (input)="forceInteger($event)">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <!-- Divider -->
        <div class="mt-11 mb-10 border-t"></div>

        <!-- Actions -->
        <div class="flex items-center justify-end">
            <button
                (click)="close()"
                mat-stroked-button
                type="button">
                Cancel
            </button>
            <button
                (click)="save()"
                class="ml-4"
                mat-flat-button
                type="button"
                [color]="'primary'"
                [disabled]="!form.valid || form.pristine">
                Save                
            </button>
        </div>
    </form>

</div>
