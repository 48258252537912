/* tslint:disable */
/* eslint-disable */

/**
 * 0 = HIRE_Customers
 * 
 * 1 = HIRE_Dashboard
 * 
 * 2 = HIRE_SalesOrders
 * 
 * 3 = HIRE_Calendar
 * 
 * 4 = HIRE_Assets
 * 
 * 5 = HIRE_Invoicing
 * 
 * 6 = CALIB_AdminDash
 * 
 * 7 = CALIB_WorkshopDash
 * 
 * 8 = CALIB_Servicing
 * 
 * 9 = CALIB_Settings
 * 
 * 10 = CALIB_Users
 * 
 * 11 = CALIB_WarehouseLocations
 * 
 * 12 = CALIB_LoadcellTypes
 * 
 * 13 = CALIB_DisplayTypes
 * 
 * 14 = CALIB_SLA
 * 
 * 15 = CALIB_PartsRegister
 * 
 * 16 = CALIB_Machines
 * 
 * 17 = CALIB_PressureTest
 * 
 * 18 = CALIB_Equipment
 * 
 * 19 = CALIB_Labour
 * 
 * 20 = CALIB_Measurements
 * 
 * 21 = CALIB_Packaging
 * 
 * 22 = CALIB_Faults
 * 
 * 23 = CALIB_Rectification
 * 
 * 24 = SETTINGS_Users
 * 
 * 25 = SETTINGS_Roles
 * 
 * 26 = SETTINGS_CompanyDetails
 * 
 * 27 = SETTINGS_ReleaseNotes
 * 
 * 28 = PROJ_Projects
 * 
 * 29 = HIRE_AssetBoard
 */
export enum Feature {
  HIRE_Customers = 0,
  HIRE_Dashboard = 1,
  HIRE_SalesOrders = 2,
  HIRE_Calendar = 3,
  HIRE_Assets = 4,
  HIRE_Invoicing = 5,
  CALIB_AdminDash = 6,
  CALIB_WorkshopDash = 7,
  CALIB_Servicing = 8,
  CALIB_Settings = 9,
  CALIB_Users = 10,
  CALIB_WarehouseLocations = 11,
  CALIB_LoadcellTypes = 12,
  CALIB_DisplayTypes = 13,
  CALIB_SLA = 14,
  CALIB_PartsRegister = 15,
  CALIB_Machines = 16,
  CALIB_PressureTest = 17,
  CALIB_Equipment = 18,
  CALIB_Labour = 19,
  CALIB_Measurements = 20,
  CALIB_Packaging = 21,
  CALIB_Faults = 22,
  CALIB_Rectification = 23,
  SETTINGS_Users = 24,
  SETTINGS_Roles = 25,
  SETTINGS_CompanyDetails = 26,
  SETTINGS_ReleaseNotes = 27,
  PROJ_Projects = 28,
  HIRE_AssetBoard = 29
}
