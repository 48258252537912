import { inject } from "@angular/core";
import { Router, CanActivateFn, CanActivateChildFn } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SettingsService } from "../../settings.service";
import { of, switchMap } from "rxjs";
import { Feature } from "app/api/models";

export const FeatureGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const snack: MatSnackBar = inject(MatSnackBar);

    var accessDenied = () => 
      snack.open("You do not have access to this page!", "OK", {
        duration: 4000,
      });

    var getFeaturePath = (feature: Feature) => {
      switch(feature) {
        case Feature.HIRE_Customers: return '/hire/customers';
        case Feature.HIRE_Dashboard: return '/hire/dashboard';
        case Feature.HIRE_SalesOrders: return '/hire/sales-orders';
        case Feature.HIRE_Calendar: return '/hire/calendar';
        case Feature.HIRE_Assets: return '/hire/assets';
        case Feature.HIRE_Invoicing: return '/hire/invoicing';
        case Feature.HIRE_AssetBoard: return '/hire/asset-board';

        case Feature.CALIB_AdminDash: return '/calibration/dashboard/admin-dashboard';
        case Feature.CALIB_WorkshopDash: return '/calibration/dashboard/workshop-dashboard';
        case Feature.CALIB_Servicing: return '/calibration/servicing';
        case Feature.CALIB_Settings: return '/calibration/settings';
        case Feature.CALIB_Users: return '/calibration/settings/users';
        case Feature.CALIB_WarehouseLocations: return '/calibration/settings/warehouse-locations';
        case Feature.CALIB_LoadcellTypes: return '/calibration/settings/loadcell-types';
        case Feature.CALIB_DisplayTypes: return '/calibration/settings/display-types';
        case Feature.CALIB_SLA: return '/calibration/settings/sla';
        case Feature.CALIB_PartsRegister: return '/calibration/settings/parts-register';
        case Feature.CALIB_Machines: return '/calibration/settings/machines';
        case Feature.CALIB_PressureTest: return '/calibration/settings/pressure-test';
        case Feature.CALIB_Equipment: return '/calibration/settings/equipment';
        case Feature.CALIB_Labour: return '/calibration/settings/labour';
        case Feature.CALIB_Measurements: return '/calibration/settings/measurements';
        case Feature.CALIB_Packaging: return '/calibration/settings/packaging';
        case Feature.CALIB_Faults: return '/calibration/settings/faults';
        case Feature.CALIB_Rectification: return '/calibration/settings/rectification';

        case Feature.SETTINGS_Users: return '/settings/users';
        case Feature.SETTINGS_Roles: return '/settings/roles';
        case Feature.SETTINGS_CompanyDetails: return '/settings/company-details';
        case Feature.SETTINGS_ReleaseNotes: return '/settings/release-notes';

        case Feature.PROJ_Projects: return '/project-management/projects';

        default: return 'invalid';
      }
    };

    // Check the authentication status
    return inject(SettingsService).apiSettingsAllowedFeaturesGet$Json$Response().pipe(
        switchMap((res) =>
        {
            var featureList = res?.body?.data;
            // console.log(featureList);

            var allowedPaths = featureList.map(x => getFeaturePath(x));
            // console.log(allowedPaths);
            // console.log(allowedPaths.filter(x => state.url.startsWith(x)))

            if(!featureList || allowedPaths.filter(x => state.url.startsWith(x)).length == 0) {
              accessDenied();

              const urlTree = router.parseUrl(`sign-in`);
              return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
}
