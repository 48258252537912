import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
// import { DLMSharedComponentsModule } from "app/views/calibrations/shared/components/shared-components.module";
// import { DLMSharedModule } from "app/views/calibrations/shared/shared.module";

@Component({
  selector: "app-labour-cost",
  templateUrl: "./labour-cost.component.html",
  styleUrls: ["./labour-cost.component.scss", "./../../../_styles/calibration-overrides.scss"],
  standalone: true,
  imports: [
    CommonModule,
    // DLMSharedModule,
    // DLMSharedComponentsModule,
    MatFormFieldModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ]
})
export class LabourCostComponent implements OnInit {
  public itemForm: FormGroup;

  public item: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LabourCostComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.item = this.data.payload;
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item) {
    this.itemForm = this.fb.group({
      labourCost: [item.labourCost || "", Validators.required],
    });
  }

  updateLabourCost() {
    this.item.labourCost = parseFloat(
      this.itemForm.get("labourCost").value.replace("£", "")
    );
    this.itemForm.get("labourCost").setValue("£" + this.item.labourCost);
  }

  submit() {
    this.dialogRef.close(this.item.labourCost);
  }
}
