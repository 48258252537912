/* tslint:disable */
/* eslint-disable */

/**
 * 0 = None
 * 
 * 1 = Low
 * 
 * 2 = Medium
 * 
 * 3 = High
 * 
 * 4 = Urgent
 */
export enum TaskPriority {
  None = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Urgent = 4
}
